import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http'; 
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServiceApiService {

  constructor(private http  : HttpClient ) { }

  
  baseUrl = environment.baseUrl ;


  getTestimonials () : Observable <any> { 
    return  this.http.get(this.baseUrl + `testimonials`)
  }

  getAboutUser () : Observable<any> {
     return this.http.get(this.baseUrl + `aboutUs`)
  }
  getExams() : Observable<any> {
    return this.http.get(this.baseUrl + `getExams`)
  }
  getCourses() : Observable<any> {
    return this.http.get(this.baseUrl + `getCourses`)
  }

  coursesExams() : Observable<any> {
    return this.http.get(this.baseUrl + `getAllExams`)
  }
  
  getLocation() : Observable<any> {
    return this.http.get(this.baseUrl + `locations`)
  }

  getFoundationalCourse() : Observable<any> {
    return this.http.get(this.baseUrl + `getFoundational`)
  }

  getWhyUs() : Observable<any> {
return this.http.get(this.baseUrl + `whyUs`)
  }

  getCourseDetails(courseId : any) : Observable<any> {

    return this.http.get(this.baseUrl + `courseDetails/${courseId}`);
  }

  getExamDetails(courseId : any) : Observable<any> {

    return this.http.get(this.baseUrl + `examsDetails/${courseId}`);
  }

  getImages() : Observable<any> {
    return  this.http.get(this.baseUrl + `gallery`)
  }

  getFiles() : Observable<any> {
    return  this.http.get<any[]>(this.baseUrl + `getUploadFiles`)
  }

  getSoicalImages() : Observable<any> {
    return  this.http.get(this.baseUrl + `getSocialImages`)
  }
  // downloadFiles(filename : any , obj : any , data : any) : Observable<any> {
  //   return  this.http.post(`this.baseUrl + `/download/${filename}` , obj, { data});
  // }

  formSubmit(courseData : any) : Observable<any> {
    return  this.http.post(this.baseUrl + `enrollment`, courseData)
  }

  getLinks() : Observable<any> {
    return this.http.get(this.baseUrl + `links`)
  }


  editWhyUs( currentId : any  ,formData : any ) : Observable<any> {
return  this.http.put( this.baseUrl + `editWhyUs/${currentId}`, formData)
  }

  addWhyUs(formData : any ) : Observable<any> {
    return this.http.post(  this.baseUrl + `createWhyUs`, formData)
  }


  deleteWhyUs( currentId : any ) : Observable<any> {
    return this.http.delete( this.baseUrl + `deleteWhyUs/${currentId}`)
  }

  deleteTestimonial( currentId : any ) : Observable<any> {
    return this.http.delete( this.baseUrl + `deleteTestimonials/${currentId}`)
  }

  addTestimonial( formData : any ) : Observable<any> {
    return this.http.post( this.baseUrl + `createTestimonial` , formData)
  }


  editTestimonial( currentId : any ,formData : any  ) : Observable<any>{
    return this.http.put( this.baseUrl + `editTestimonials/${currentId}` , formData)
  }

  deleteLink( currentId : any ) : Observable<any> {
    return this.http.delete( this.baseUrl + `deleteLinks/${currentId}`)
  }

  editLink ( currentId : any ,formData : any): Observable<any>{
    return this.http.put( this.baseUrl + `editSettings/${currentId}` , formData)
  }

  addLinks(formData : any ) : Observable<any>{
    return this.http.post( this.baseUrl + `createTestimonial` , formData)
  }

  addAbout(formData : any ) : Observable<any>{
    return this.http.post( this.baseUrl + `createAboutUs` , formData)
  }

  deleteAbout(currentId : any ) : Observable<any> {
    return this.http.delete( this.baseUrl + `deleteAboutUs/${currentId}`)
  }

  editAbout(currentId : any ,formData : any): Observable<any>{
    return this.http.put( this.baseUrl + `editAboutus/${currentId}` , formData)
  }

  addLocation(formData : any ) : Observable<any>{
    return this.http.post( this.baseUrl + `createLocation` , formData)
  }

  editLocation(currentId : any ,formData : any): Observable<any>{
    return this.http.put( this.baseUrl + `locations/${currentId}` , formData)
  }


  deleteLocation(currentId : any ) : Observable<any> {
    return this.http.delete( this.baseUrl + `deleteLocations/${currentId}`)
  }

  addExams(formData : any ) : Observable<any>{
    return this.http.post( this.baseUrl + `exams` , formData)
  }


  deleteExams(currentId : any ) : Observable<any> {
    return this.http.delete( this.baseUrl + `deleteExams/${currentId}`)
  }

  editExams(currentId : any ,formData : any): Observable<any>{
    return this.http.put( this.baseUrl + `editExams/${currentId}` , formData)
  }


  addCourse(formData : any ) : Observable<any>{
    return this.http.post( this.baseUrl + `createCourse` , formData)
  }

  deleteCourse(currentId : any ) : Observable<any> {
    return this.http.delete( this.baseUrl + `deleteCourse/${currentId}`)
  }

  editCourse(currentId : any ,formData : any): Observable<any>{
    return this.http.put( this.baseUrl + `editCourse/${currentId}` , formData)
  }


  getBanner(): Observable<any> {
    return this.http.get(this.baseUrl + `getBanner`)
  }

  login(email : any , password : any) : Observable<any>{
    return this.http.post<any>(this.baseUrl + `login`, { email, password }) 
  }


  uploadGallery(formData : any ) : Observable<any> {
    return this.http.post<any>(this.baseUrl + `upload`, formData) 
  }

  getBannner() : Observable<any> {
    return this.http.get<any>(this.baseUrl + `getBanner`) 
  }

  getSocialImages() :Observable<any> {
    return this.http.get<any>(this.baseUrl + `getSocialImages`) 
  }
  
  
  updatePassword(currentId: any, obj: any, httpOptions: any) {
    return this.http.post(this.baseUrl + `changePassfromMysql/${currentId}`, obj, httpOptions);
  }
  

  getLists() : Observable<any> {
    return this.http.get<any>(this.baseUrl + `getLists`)
  }

}
