import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceApiService } from 'src/app/shared/service-api.service';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css']
})
export class CoursesComponentAdmin implements OnInit {

  courses: any[] = [];
  delBox = false;
  currentId: string = "";
  updateBox = false;
  selectedCourse: any = {};
  ErrorMessage: string = "";
  form: FormGroup;
  selectedFile: File | null = null;
  selectedFile1: File | null = null;
  maxFileSize: number = 10485760; // 10 MB in bytes
  showUploadButton: boolean = false;
 

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private serviceApiService: ServiceApiService
  ) {
    this.form = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      start_date: [''],
      end_date: [''],
      capacity: ['', Validators.required],
      current_enrollment: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.getCourse();
  }

  getCourse() {
    this.serviceApiService.getCourses().subscribe(
      (data) => {
        this.courses = data;
      },
      (error) => {
        console.error('Error fetching courses:', error);
      }
    );
  }

  addCourse() {
    if (this.form.valid && this.selectedFile && this.selectedFile1) {
      const formData = new FormData();
      formData.append('name', this.form.value.name);
      formData.append('description', this.form.value.description);
      formData.append('start_date', this.form.value.start_date);
      formData.append('end_date', this.form.value.end_date);
      formData.append('capacity', this.form.value.capacity);
      formData.append('current_enrollment', this.form.value.current_enrollment);
      formData.append('photo', this.selectedFile);
      formData.append('background_photo', this.selectedFile1);

      this.serviceApiService.addCourse(formData).subscribe(
        () => {
          console.log('Course added successfully');
          this.form.reset();
          this.getCourse();
        },
        error => {
          console.error('Error adding course:', error);
        }
      );
    } else {
      console.log('Invalid form data. Please check your inputs.');
    }
  }

  onFileSelected(event: any): void {
    this.selectedFile = event.files[0];
  }

  onBackgroundImageSelected(event: any): void {
    this.selectedFile1 = event.files[0];
  }

  del(course: any): void {
    this.selectedCourse = { ...course };
    this.delBox = true;
    this.deleteCourseConfirmation(course);
  }

  deleteCourseConfirmation(course: any): void {
    this.currentId = course.id;
  }

  closeDialog() {
    this.updateBox = false;
  }

  deleteCourse(): void {
    this.serviceApiService.deleteCourse(this.currentId).subscribe(
      (resultData: any) => {
        console.log(resultData);
        this.delBox = false;
        this.getCourse();
      },
      (error) => {
        console.error('Error deleting course:', error);
      }
    );
  }

  edit(course: any): void {
    this.updateBox = true;
    this.selectedCourse = course;
    this.form.patchValue(this.selectedCourse);
    this.editCourseConfirmation(course);
  }

  editCourseConfirmation(course: any): void {
    this.currentId = course.id;
  }

  editCourse(): void {
    const courseData = {
      name: this.form.get('name')!.value,
      description: this.form.get('description')!.value,
      start_date: this.form.get('start_date')!.value,
      end_date: this.form.get('end_date')!.value,
      capacity: this.form.get('capacity')!.value,
      current_enrollment: this.form.get('current_enrollment')!.value
    };

    this.serviceApiService.editCourse(this.currentId, courseData).subscribe(
      (resultData: any) => {
        console.log('Server Response:', resultData);
        this.selectedCourse = resultData;
        this.form.patchValue(this.selectedCourse);
        this.updateBox = false;

        this.getCourse();
      },
      (error) => {
        console.error('Error updating course:', error);
      }
    );
  }
}
