<div class="upload-container">
    <h2>Material Uploads</h2>
 

    <div class="form-group">
        <label class="form-label">Title:</label>
        <input type="text" [(ngModel)]="title" class="form-input" />
    </div>

    <div class="form-group">
        <label class="form-label">Course:</label>
        <div class="dropdown-container">
            <p-dropdown [options]="courses" optionLabel="name" [(ngModel)]="selectedCourse"
                        placeholder="Select a course" [showClear]="true" [filter]="true">
            </p-dropdown>
        </div>
    </div>

    <div class="input-group">
        <label class="upload-label">File Upload: </label>
        <p-fileUpload name="file_url" (onSelect)="onFileSelected($event)" accept="image/*" 
                      [maxFileSize]="maxFileSize" mode="basic" [showUploadButton]="showUploadButton"
                      url="http://localhost:8080/uploadFile">
        </p-fileUpload>
    </div>

    <div class="button-container">
        <p-button (click)="uploadFile()" class="upload-button">Submit</p-button>
    </div>
</div>


<div *ngFor="let file of files" class="testimonial-item">
    <span>{{ file.title }}</span>
    <span>{{ file.file_url }}</span>
    <span>{{ file.course_id }}</span>
    <div class="button-group">
      <button class="edit-btn" (click)="edit(file)">Edit</button>
      <button class="delete-btn" (click)="del(file)">Delete</button>
  </div>
  </div>



  <!-- ///////////////////////////////////////////////////////////////////////////////////////////// -->



  <p-dialog [(visible)]="deleteBox" [modal]="true" [draggable]="true" [resizable]="true" class="del-dialog">
    <div class="del-header">
      <h1>Are You Sure You Want To Delete?</h1>
    </div>
  
    <ng-template pTemplate="footer">
      <p-footer>
        <p-button type="button" (click)="deleteTestimonial()" styleClass="p-button-raised p-button-danger">Delete</p-button>
        <button pButton type="button" (click)="closeDialog()" label="Cancel" ></button>
      </p-footer>
    </ng-template>
  </p-dialog>


  <!-- //////////////////////////////////////////////////////////////////////////////////////////////////// -->


  