<div class="signup-container">
    <h2>Change Password</h2>
    <form (ngSubmit)="changepassword()">
      <div>
        <label for="currentpassword">Current Password:</label>
        <input type="password" id="currentpassword" name="currentpassword" [(ngModel)]="currentPassword" required>
      </div>
      <div>
        <label for="newpassword"> New Password:</label>
        <input type="password" id="newpassword" name="newpassword" [(ngModel)]="newPassword" required>
      </div>
      <div>
        <label for="Confirmpassword"> Confirm Password:</label>
        <input type="password" id="Confirmpassword" name="Confirmpassword" [(ngModel)]="confirmPassword" required>
      </div>
      <button type="submit">Change Password</button>
    </form>
  </div>
  