

<app-header> </app-header>

    <div class="courses">
      <h1 >Courses</h1>
        <div class="course-box" *ngFor="let course of details">
           
          <div class="backgroundImg">
            <!-- Background content goes here -->
            <img src={{course.background_photo}} alt="Background Image" class="background-content">
            <!-- Course image goes here -->
            <img src={{course.photo}} alt="Course Image" class="courseImage">
          </div>


            <div class="course-content">
              <h2>{{ course.name }}</h2>
              <p>{{ course.description }}</p>
          </div>
        </div>
      </div>
      
      

      <app-footer></app-footer>
  
  






