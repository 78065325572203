<div class="settings">
    <h2>About Us</h2>

    <div class="form" [formGroup]="aboutUsForm">
        <input formControlName="title" placeholder="Enter Title">
        <input formControlName="content" placeholder="Enter Content">
    
        
        <button class="add-btn" (click)="addAboutUs()">Add About Us</button>
    </div>
    

    <div *ngFor="let link of aboutUs" class="why_us-item">
        <span>{{ link.title }}</span>
        <span>{{ link.content }}</span>
        <span>{{ link.founder_name }}</span>
       
        <div class="button-group">
            <button class="edit-btn" (click)="edit(link)">Edit</button>
            <button class="delete-btn" (click)="del(link)">Delete</button>
        </div>
    </div>
</div>


<p-dialog [(visible)]="delBox" [modal]="true" [draggable]="true" [resizable]="true" class="del-dialog">
    <div class="del-header">
      <h1>Are You Sure You Want To Delete?</h1>
    </div>
    
    <ng-template pTemplate="footer">
      <p-footer>
        <p-button type="button" (click)="deleteCourse()" styleClass="p-button-raised p-button-danger">Delete</p-button>
        <button pButton type="button" (click)="closeDialog()" label="Cancel" ></button>
      </p-footer>
    </ng-template>
    </p-dialog>



    <!-- //////////////////////////////////////////////////////////////////////////////////////////////////// -->

    <p-dialog [(visible)]="updateBox" [modal]="true" [draggable]="true" [resizable]="true">
      <div class="dialog-content">
        <form [formGroup]="aboutUsForm">
          <div class="form-group">
            <label for="title">Title:</label>
            <input type="text" formControlName="title" id="title" pInputText required>
          </div>
    
          <div class="form-group">
            <label for="content">Content:</label>
            <input type="text" formControlName="content" id="content" pInputText required>
          </div>
    
        
    
          <div class="error-message" *ngIf="ErrorMessage">
            {{ ErrorMessage }}
          </div>
    
          <!-- <ng-template pTemplate="footer">
            <p-footer> -->
              <button pButton type="button" (click)="editCourse()" label="Submit" class="p-button-success"></button>
              <button pButton type="button" (click)="closeDialog()" label="Cancel" class="p-button-secondary"></button>
            <!-- </p-footer>
          </ng-template> -->
        </form>
      </div>
    </p-dialog>
    