<div class="settings">
    <h2>Why Us</h2>

        <div class="form" [formGroup]="whyUsForm">
            <input formControlName="title" placeholder="Enter Why Us">
            <input formControlName="content" placeholder="Enter Content">
            <input formControlName="features" placeholder="Enter Vision Mission Heading">
            <input formControlName="benefits" placeholder="Enter Vision Mission Content">
            <button class="add-btn" (click)="addWhyUs()">Add Why Us</button>
        </div>
        

    <div *ngFor="let link of aboutUs" class="why_us-item">
        <span>{{ link.title }}</span>
        <span>{{ link.content }}</span>
        <span>{{ link.features }}</span>
        <span>{{ link.benefits }}</span>
        <div class="button-group">
            <button class="edit-btn" (click)="edit(link)" >Edit</button>
            <button class="delete-btn" (click)="del(link)">Delete</button>
        </div>
    </div>
</div>



<p-dialog [(visible)]="delBox" [modal]="true" [draggable]="true" [resizable]="true" class="del-dialog">
    <div class="del-header">
      <h1>Are You Sure You Want To Delete?</h1>
    </div>
    
    <ng-template pTemplate="footer">
      <p-footer>
        <p-button type="button" (click)="deleteCourse()" styleClass="p-button-raised p-button-danger">Delete</p-button>
        <button pButton type="button" (click)="closeDialog()" label="Cancel" ></button>
      </p-footer>
    </ng-template>
    </p-dialog>


    <p-dialog [(visible)]="updateBox" [modal]="true" [draggable]="true" [resizable]="true">
      <div class="dialog-content">
        <form [formGroup]="whyUsForm">
          <div class="form-group">
            <label for="title">Title:</label>
            <input type="text" formControlName="title" id="title" pInputText required>
          </div>
        
          <div class="form-group">
            <label for="content">Content:</label>
            <input type="text" formControlName="content" id="content" pInputText required>
          </div>
    
          <!-- Assuming you have form controls for features and benefits as well -->
          <div class="form-group">
            <label for="features">Features:</label>
            <input type="text" formControlName="features" id="features" pInputText required>
          </div>
    
          <div class="form-group">
            <label for="benefits">Benefits:</label>
            <input type="text" formControlName="benefits" id="benefits" pInputText required>
          </div>
        
          <div class="error-message" *ngIf="ErrorMessage">
            {{ ErrorMessage }}
          </div>
        
          <div class="button-container">
            <button pButton type="button" (click)="editCourse()" label="Submit" class="p-button-success"></button>
            <button pButton type="button" (click)="closeDialog()" label="Cancel" class="p-button-secondary"></button>
          </div>
        </form>
      </div>
    </p-dialog>
    