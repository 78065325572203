<app-header> </app-header>

<div class="whyus">
  <ng-container *ngFor="let whyUs of whyUs">
    <h2 class="whyus-heading1">{{ whyUs.features }}</h2>
  </ng-container>
  <img src="../../../assets/images/Asset 17@4x.png" alt="img" />
  <ng-container *ngFor="let whyUs of whyUs">
    <p class="whyus-para">{{ whyUs.content }}</p>
  </ng-container>
</div>

<div class="enroll-now">
  <form [formGroup]="formData" class="enroll-form">
    <div class="p-field">
      <h2>Enroll Now!</h2>
      <label for="name">Name : </label>
      <input id="name" type="text" formControlName="name" pInputText  class="custom-input"/>
    </div>

    <div class="p-field">
      <label for="email">Email : </label>
      <input id="email" type="email" formControlName="email" pInputText  class="custom-input"/>
    </div>

    <div class="p-field">
      <label for="phone">Phone : </label>
      <input id="phone" type="tel" formControlName="phone" pInputText  class="custom-input"/>
    </div>

    <label for="course">Select a Course : </label>
    <div class="p-field">
      <p-dropdown
        [options]="courses"
        optionLabel="name"
        formControlName="courseId"
        placeholder="Select a course"
        [showClear]="true"
      >
      </p-dropdown>
    </div>

    <p-toast></p-toast>

    <div class="p-field">
      <label for="message">Message : </label>
      <textarea
        id="message"
        formControlName="message"
        pInputTextarea
        rows="5"
        class="custom-input"
      ></textarea>
    </div>

    <div class="p-field">
      <button type="button" pButton (click)="submitForm()">Submit</button>
    </div>
  </form>

  <div class="enroll-image">
    <img src="../../assets/images/enroll-img.jpg" alt="Enroll Now Image" />
  </div>
</div>

<app-footer></app-footer>
