import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute  ,Router} from '@angular/router';
import { ServiceApiService } from 'src/app/shared/service-api.service';


@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent {

  activeIndex = 0;
  aboutUs : any = [];
  locations : any = [];
  courses : any = [];
  formData: FormGroup;
  whyUs : any = []; 
  courseId: Number = 0;
 

  private _photos: string[] = [
    '../../assets/images/enroll-img.jpg',
    '../../assets/images/img2.png',
    '../../assets/images/images.jpg'
  ];

    constructor(private fb: FormBuilder , private http: HttpClient , private route: ActivatedRoute , private router: Router , private serviceApiService : ServiceApiService) { 

      this.formData = this.fb.group({
        name: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        phone: ['', Validators.required],
        courseId: [null, Validators.required], // assuming selectedCourse is an object
        message: ['']
      });
    }


 


    ngOnInit(): void {
    

     this.getwhyUs();
     this.getCourses(); 
  }



    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////




    submitForm(): void {
      if (this.formData.valid) {
        const courseId = this.formData.value.courseId.id; // Extract the id property
        const courseData = {
          ...this.formData.value, 
          courseId: courseId // Add the extracted id to the courseData object
        };
      
  
        this.serviceApiService.formSubmit(courseData).subscribe(
          (response: any) => {
            console.log('Course added successfully:', response);
            
            this.formData.reset();
          },
          (error) => {
            console.error('Error adding course:', error);
          }
        );
      } else {
        this.formData.markAllAsTouched();
      }
    }
  

    getCourses() {
      this.serviceApiService.getCourses().subscribe(
        (data) => {
          console.log(data);
          
          this.courses = data;
        },
        (error) => {
          console.error('Error fetching courses:', error);
        }
      );
    }
    



    getwhyUs() {
      this.serviceApiService.getWhyUs().subscribe(
        (data) => {
          console.log(data);
          
          this.whyUs = data;
        },
        (error) => {
          console.error('Error fetching courses:', error);
        }
      );
  
    }
}
