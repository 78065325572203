import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceApiService } from 'src/app/shared/service-api.service';

@Component({
  selector: 'app-gallery-admin',
  templateUrl: './gallery-admin.component.html',
  styleUrls: ['./gallery-admin.component.css']
})
export class GalleryAdminComponent implements OnInit {

  // ... (existing code)
  BannerImages: any = [];
  galleryImages : any = [];
  SocialImages: any = [];
  form: FormGroup;
  selectedFile: File | null = null;
  maxFileSize: number = 10485760; // 10 MB in bytes
  showUploadButton: boolean = false;
  categories: any[] = ['banner', 'social', 'gallery']; // Add this line
  deleteBox = false;
  currentId : string = ""; 
  selectedCourse: any;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private serviceApiService: ServiceApiService
  ) {
    this.form = this.fb.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      category: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.getBannerImages();
    this.getImages();
    this.getSocialImages();
    
  }


    getBannerImages() {
    this.serviceApiService.getBannner().subscribe(
      (data) => {
        this.BannerImages = data;
      },
      (error) => {
        console.error('Error fetching courses:', error);
      }
    );
  }


  getImages() {
    this.serviceApiService.getImages().subscribe(
      (data) => {
        console.log(data);
        
        this.galleryImages = data;
        console.log(this.galleryImages);
        
      },
      (error) => {
        console.error('Error fetching courses:', error);
      }
    );
  }

  getSocialImages() {
    this.serviceApiService.getSocialImages().subscribe(
      (data) => {
        console.log(data);
        
        this.SocialImages = data;
        console.log(this.SocialImages);
        
      },
      (error) => {
        console.error('Error fetching courses:', error);
      }
    );
  }


  uploadGallery() {

    if (this.form.valid && this.selectedFile) {
      const formData = new FormData();
      formData.append('title', this.form.value.title);
      formData.append('description', this.form.value.description);
      formData.append('category', this.form.value.category);
      formData.append('image_url', this.selectedFile);
      

      this.serviceApiService.uploadGallery(formData).subscribe(
        () => {
          console.log('gallery added successfully');
          this.form.reset();
          // this.getCourse();
        },
        error => {
          console.error('Error adding gallery:', error);
        }
      );
    } else {
      console.log('Invalid form data. Please check your inputs.');
    }
  }

  onFileSelected(event: any): void {
 
    this.selectedFile = event.files[0];
 
}
  
deleteGallery(): void {
  const url = `http://localhost:8080/deleteGallery/${this.currentId}`;

  this.http.delete(url, { responseType: 'text' }).subscribe(
    (resultData: any) => {
      console.log('Server Response:', resultData);
      this.deleteBox = false;
      this.BannerImages();
      this.galleryImages() ;
      this.SocialImages();
    },
    (error) => {
      console.error('Error deleting testimonial:', error);
    }
  );
}


closeDialog() {
 
  this.deleteBox = false;
}



del(image: any): void {
  this.selectedCourse = { ...image};
  this.currentId = image.id;
  this.deleteBox = true;
}

deleteCourseConfirmation(course: any) {
  console.log(course);
  
  this.currentId = course.id; 
  console.log(this.currentId);
  
}

  

}
