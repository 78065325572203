import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServiceApiService } from 'src/app/shared/service-api.service';


@Component({
  selector: 'app-material-uploads',
  templateUrl: './material-uploads.component.html',
  styleUrls: ['./material-uploads.component.css']
})
export class MaterialUploadsComponent {
  uploadedFiles: any[] = [];
  title: string = '';
  selectedCourseId : number = 1 ; // Initialize with a default value
  courses: any[] = []; // Array to store course options
  maxFileSize: number = 10485760; // 10 MB in bytes
  showUploadButton: boolean = false; // Set to true if you want the upload button to be initially visible
  selectedFile: File | null = null;
  selectedCourse: any;
  files  : any =[] ;
  updateBox = false;
  deleteBox = false;
  currentId : string = ""; 

  constructor(private http: HttpClient, private serviceApiService : ServiceApiService) {
  

    console.log(  this.currentId );
    
   }

  ngOnInit(): void {
    this.getCourses();
    this.getFileUpload();
    
  }

  onFileSelected(event: any): void {
 
      this.selectedFile = event.files[0];
   
  }


    uploadFile(): void {
    if (this.selectedCourse.id && this.selectedFile) {
     
      
      const formData: FormData = new FormData();
      formData.append('file_url', this.selectedFile);
      formData.append('title', this.title);
      formData.append('courseId', this.selectedCourse.id);

      this.http.post<any>('http://localhost:8080/uploadFile', formData).subscribe(
        (data) => {
          this.uploadedFiles.push(data);
          console.log('File uploaded successfully:', data);
        },
        (error) => {
          console.error('Error uploading file:', error);
        }
      );
    }
  }

  getCourses(): void {
    this.serviceApiService.getFiles().subscribe(
      (data) => {
        console.log(data);
        this.courses = data;
      },
      (error) => {
        console.error('Error fetching courses:', error);
      }
    );
  }


  getFileUpload() : void {

    this.http.get<any[]>('http://localhost:8080/getUploadFiles').subscribe(
      (data) => {
        console.log(data);
        this.files = data;
      },
      (error) => {
        console.error('Error fetching courses:', error);
      }
    );
  }



  edit(testimonial : any ): void {
    this.updateBox = true;
   
  }

  closeDialog() {
    this.updateBox = false;
    this.deleteBox = false;
  }
  
  

  del(course: any): void {
    this.selectedCourse = { ...course };
    this.currentId = course.id; // Set the current ID
    this.deleteBox = true;
  }
  

deleteCourseConfirmation(course: any) {
  console.log(course);
  
  this.currentId = course.id; 
  console.log(this.currentId);
  
}

  
  
deleteTestimonial(): void {
  const url = `http://localhost:8080/deletefileUploads/${this.currentId}`;

  this.http.delete(url, { responseType: 'text' }).subscribe(
    (resultData: any) => {
      console.log('Server Response:', resultData);
      this.deleteBox = false;
      this.getFileUpload();
    },
    (error) => {
      console.error('Error deleting testimonial:', error);
    }
  );
}


}



// import { Component, OnInit } from '@angular/core';
// import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { HttpClient } from '@angular/common/http';
// import { ActivatedRoute, Router } from '@angular/router';
// import { ServiceApiService } from 'src/app/shared/service-api.service';

// @Component({
//   selector: 'app-gallery-admin',
//   templateUrl: './gallery-admin.component.html',
//   styleUrls: ['./gallery-admin.component.css']
// })
// export class GalleryAdminComponent implements OnInit {

//   courses: any[] = [];
//   delBox = false;
//   currentId: string = "";
//   updateBox = false;
//   selectedCourse: any = {};
//   ErrorMessage: string = "";
//   form: FormGroup;
//   selectedFile: File | null = null;
//   maxFileSize: number = 10485760; // 10 MB in bytes
//   showUploadButton: boolean = false;
 

//   constructor(
//     private http: HttpClient,
//     private route: ActivatedRoute,
//     private router: Router,
//     private fb: FormBuilder,
//     private serviceApiService: ServiceApiService
//   ) {
//     this.form = this.fb.group({
//       title: ['', Validators.required],
//       description: ['', Validators.required],
//       category: ['', Validators.required]
//     });
//   }

//   ngOnInit(): void {
//     // this.getCourse();
//   }



//   let category = [ 'banner' , 'social' , 'gallery']

//   uploadGallery() {
//     if (this.form.valid && this.selectedFile) {
//       const formData = new FormData();
//       formData.append('name', this.form.value.name);
//       formData.append('description', this.form.value.description);
//       formData.append('category', this.form.value.category);
//       formData.append('image_url', this.selectedFile);
    

//       this.serviceApiService.uploadGallery(formData).subscribe(
//         () => {
//           console.log('gallery added successfully');
//           this.form.reset();
//           // this.getCourse();
//         },
//         error => {
//           console.error('Error adding gallery:', error);
//         }
//       );
//     } else {
//       console.log('Invalid form data. Please check your inputs.');
//     }
//   }

//   onFileSelected(event: any): void {
//     this.selectedFile = event.files[0];
//   }


//   // del(course: any): void {
//   //   this.selectedCourse = { ...course };
//   //   this.delBox = true;
//   //   this.deleteCourseConfirmation(course);
//   // }

//   // deleteCourseConfirmation(course: any): void {
//   //   this.currentId = course.id;
//   // }

//   // closeDialog() {
//   //   this.updateBox = false;
//   // }

//   // deleteCourse(): void {
//   //   this.serviceApiService.deleteCourse(this.currentId).subscribe(
//   //     (resultData: any) => {
//   //       console.log(resultData);
//   //       this.delBox = false;
//   //       this.getCourse();
//   //     },
//   //     (error) => {
//   //       console.error('Error deleting course:', error);
//   //     }
//   //   );
//   // }

//   // edit(course: any): void {
//   //   this.updateBox = true;
//   //   this.selectedCourse = course;
//   //   this.form.patchValue(this.selectedCourse);
//   //   this.editCourseConfirmation(course);
//   // }

//   // editCourseConfirmation(course: any): void {
//   //   this.currentId = course.id;
//   // }

//   // editCourse(): void {
//   //   const courseData = {
//   //     name: this.form.get('name')!.value,
//   //     description: this.form.get('description')!.value,
//   //     start_date: this.form.get('start_date')!.value,
//   //     end_date: this.form.get('end_date')!.value,
//   //     capacity: this.form.get('capacity')!.value,
//   //     current_enrollment: this.form.get('current_enrollment')!.value
//   //   };

//   //   this.serviceApiService.editCourse(this.currentId, courseData).subscribe(
//   //     (resultData: any) => {
//   //       console.log('Server Response:', resultData);
//   //       this.selectedCourse = resultData;
//   //       this.form.patchValue(this.selectedCourse);
//   //       this.updateBox = false;

//   //       this.getCourse();
//   //     },
//   //     (error) => {
//   //       console.error('Error updating course:', error);
//   //     }
//   //   );
//   // }
// }
