import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ServiceApiService } from 'src/app/shared/service-api.service';


interface SocialLink {
  id: string;
  emails: string;
  fb_llink: string;
  description: string;
  twitter_links: string;
  insta_links: string;
}

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  updateBox: boolean = false;
  delBox: boolean = false;
  links: SocialLink[] = [];
  currentId: string = '';
  socialLinksForm: FormGroup;
  ErrorMessage: string = '';

  constructor(private fb: FormBuilder, private http: HttpClient , private serviceApiService : ServiceApiService ) {
    this.socialLinksForm = this.fb.group({
      emails: ['', Validators.required],
      fb_llink: ['', Validators.required],
      description: [''],
      twitter_links: ['', Validators.required],
      insta_links: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.getSocialLinks();
  }

  getSocialLinks(): void {
    this.serviceApiService.getLinks().subscribe(
      (data) => {
        this.links = data;
      },
      (error) => {
        console.error('Error fetching social links:', error);
      }
    );
  }



  ///////////////////////////////////////////////////////////////////////////////////////////
  del(testimonial: SocialLink): void {
    this.delBox = true;
    this.currentId = testimonial.id;
  }

  deleteTestimonial(): void {
  this.serviceApiService.deleteLink(this.currentId).subscribe(
      () => {
        this.delBox = false;
        this.getSocialLinks(); // Refresh the social links after deletion
      },
      (error) => {
        console.error('Error deleting testimonial:', error);
      }
    );
  }

  edit(testimonial: SocialLink): void {
    this.updateBox = true;
    this.currentId = testimonial.id;
    this.socialLinksForm.patchValue(testimonial);
  }

  closeDialog(): void {
    this.updateBox = false;
    this.delBox = false;
  }

  editTestimonial(): void {
    if (this.socialLinksForm.valid) {
      const bodyData = this.socialLinksForm.value;
     this.serviceApiService.editLink(this.currentId, bodyData).subscribe(
        () => {
          this.updateBox = false;
          this.getSocialLinks(); // Refresh the social links after editing
        },
        (error) => {
          console.error('Error updating testimonial:', error);
        }
      );
    }
  }

  addLinks(): void {
    const formData = this.socialLinksForm.value;
    console.log(formData);
    
    this.serviceApiService.addLinks(formData).subscribe(
      () => {
        this.socialLinksForm.reset();
        this.getSocialLinks();
      },
      (error) => {
        console.error('Error adding data to social links:', error);
      }
    );
  }
}
