<app-header> </app-header>

<div class="About-us">
  <div class="row">
    <h1 class="aboutUsHeading d-flex d-md-none">About Us</h1>

    <div class="col-md-7">
      <div class="aboutUsPhotoSection1">
        <img src="../../../assets/images/9915.jpg" alt="Course Image" />
        <h1>Prof. Sandeep R. Singh</h1>
      </div>
      <div class="aboutUsPhotoSection2">
        <img src="../../../assets/images/9910.jpg" alt="Carousel Image" />
        <h1>Prof. Shilpa S. Singh</h1>
      </div>
    </div>
    <div class="col-md-5">
      <ng-container *ngFor="let Aboutus of aboutUs">
        <h1 class="aboutUsHeading d-none d-md-flex">{{ Aboutus.title }}</h1>
      </ng-container>
      <ng-container *ngFor="let Aboutus of aboutUs">
        <h2>{{ Aboutus.founder_name }}</h2>
      </ng-container>
      <ng-container *ngFor="let Aboutus of aboutUs">
        <p class="aboutUs-para">{{ Aboutus.content }}</p>
      </ng-container>
    </div>
  </div>
  <!-- <div class="aboutUsImage" data-aos="fade-right" data-aos-duration="4000">
    <div class="imgContainer">
      <img
        src="../../../assets/images/images.jpg"
        alt="Course Image"
        class="aboutUsPhoto1"
      />
      <h1 class="imageHeading1">PROF. SANDEEP Sir</h1>
      <img
        src="../../../assets/images/images.jpg"
        alt="Carousel Image"
        class="aboutUsPhoto2"
      />
      <h1 class="imageHeading2">PROF. SHILPA MA'AM</h1>
    </div>
  </div>
  <div
    class="aboutUsIntro"
    data-aos="fade-left"
    data-aos-duration="4000"
    data-aos-easing="linear"
  >
    <ng-container *ngFor="let Aboutus of aboutUs">
      <h1 class="aboutUsHeading">{{ Aboutus.title }}</h1>
    </ng-container>
    <ng-container *ngFor="let Aboutus of aboutUs">
      <h2>{{ Aboutus.founder_name }}</h2>
    </ng-container>
    <ng-container *ngFor="let Aboutus of aboutUs">
      <p class="aboutUs-para">{{ Aboutus.content }}</p>
    </ng-container>
  </div> -->
</div>

<div class="Courses">
  <h1>Courses</h1>

  <div class="subCourses">
    <p-carousel
      [value]="coursesExams"
      [numScroll]="1"
      [numVisible]="3"
      [showIndicators]="false"
      [responsiveOptions]="responsiveOptions"
    >
      <ng-template let-course let-i="index" pTemplate="item">
        <div class="coursesContent">
          <img [src]="course.photo" [alt]="course.name" class="courses-image" />
          <p-button
            type="button"
            label="Know More"
            class="courseButton"
            (click)="navigateToCourse(course.id)"
          ></p-button>
        </div>
      </ng-template>
    </p-carousel>
  </div>
</div>

<div class="Testimonials">
  <h1>Testimonials</h1>
  <div class="innerTestimonial">
    <!-- <img src="../../../assets/images/Asset 5@4x.png" alt="img" class="image1" /> -->
    <!-- <div class="testimonialcontainer"> -->
    <!-- data-aos="zoom-in"
    data-aos-duration="1000"
    data-aos-easing="linear" -->
    <div class="testimonialcontainer">
      <p-carousel
        [value]="testimonals"
        [numScroll]="1"
        [autoplayInterval]="3000"
        [showNavigators]="false"
      >
        <ng-template let-testimonial pTemplate="item">
          <div style="display: flex; background-color: #fff; margin: 24px 0">
            <img
              src="../../../assets/images/Asset 5@4x.png"
              alt="img"
              class="image1"
            />

            <div
              class="innerContainer"
              style="
                background-color: #f9f9f9;
                border-radius: 16px;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
              "
            >
              <div class="testimonial-content">
                <p>{{ testimonial.text }}</p>
                <img
                  [src]="testimonial.photo"
                  [alt]="testimonial.name"
                  class="testimonial-image"
                />
              </div>

              <div class="testimonial-footer">
                <p>{{ testimonial.name }}</p>
                <p-rating
                  [(ngModel)]="testimonial.rating"
                  [readonly]="true"
                  [cancel]="false"
                  class="rating"
                ></p-rating>
              </div>
            </div>
            <img
              src="../../../assets/images/Asset 6@4x.png"
              alt="img"
              class="image2"
            />
          </div>
        </ng-template>
      </p-carousel>
    </div>
    <!-- <img src="../../../assets/images/Asset 6@4x.png" alt="img" class="image2" /> -->
  </div>
</div>

<div class="Gallery">
  <h1 class="gallery-title">Gallery</h1>
  <div class="image-container">
    <div *ngIf="galleryImages && galleryImages.length > 0">
      <div
        *ngFor="let image of galleryImages.slice(0, 3)"
        class="gallery-item"
        data-aos="zoom-in-up"
        data-aos-duration="1000"
        data-aos-easing="ease-out"
      >
        <img
          [src]="image.image_url"
          [alt]="image.title"
          class="gallery-image"
        />
      </div>
    </div>
  </div>
  <br />
  <p-button
    class="courseButton"
    label="VIEW ALL"
    routerLink="/gallery"
  ></p-button>
</div>

<div class="visitUs">
  <div
    class="visitUs-container"
    data-aos="zoom-in-up"
    data-aos-duration="6000"
    data-aos-easing="linear"
    style="width: fit-content;"
  >
    <div *ngIf="socialImages && socialImages.length > 0">
      <div
        *ngFor="let image of socialImages.slice(0, 3)"
        class="gallery-item1"
        data-aos="zoom-in-up"
        data-aos-duration="1000"
        data-aos-easing="ease-out"
      >
        <img
          [src]="image.image_url"
          [alt]="image.title"
          class="gallery-image1"
        />
      </div>
    </div>
  </div>
  <br>
  <p-button class="courseButton" > VISIT US </p-button>
</div>

<div class="enroll-now" id="enrollNow">
  <form
    [formGroup]="formData"
    class="enroll-form"
    data-aos="zoom-in-right"
    data-aos-duration="1000"
    data-aos-easing="linear"
  >
    <div class="p-field w-100">
      <h2>Enroll Now!</h2>
      <label for="name">Name : </label>
      <input
        id="name"
        type="text"
        formControlName="name"
        pInputText
        class="custom-input"
      />
    </div>

    <div class="p-field">
      <label for="email">Email : </label>
      <input
        id="email"
        type="email"
        formControlName="email"
        pInputText
        class="custom-input"
      />
    </div>

    <div class="p-field">
      <label for="phone">Phone : </label>
      <input
        id="phone"
        type="tel"
        formControlName="phone"
        pInputText
        class="custom-input"
      />
    </div>

    <label for="course">Select a Course : </label>
    <div class="p-field">
      <p-dropdown
        [options]="coursesExams"
        optionLabel="name"
        formControlName="courseId"
        placeholder="Select a course"
        [showClear]="true"
      >
      </p-dropdown>
    </div>

    <div class="p-field">
      <label for="message">Message : </label>
      <textarea
        id="message"
        formControlName="message"
        pInputTextarea
        rows="5"
        class="custom-input"
      ></textarea>
    </div>

    <div class="p-field">
      <button type="button" pButton (click)="submitForm()" >Submit</button>
    </div>
  </form>
  <div class="enroll-image">
    <img src="../../assets/images/enroll-img.jpg" alt="Enroll Now Image" />
  </div>
</div>

<app-footer></app-footer>
