<app-header> </app-header>
<div class="main-blogs">
  <h1>Blogs</h1>
  <div class="blogs-list" *ngIf="!id">
    <div
      *ngFor="let item of blogs"
      class="blogs-item"
      (click)="onClickBlog(item.id)"
    >
      <h4>{{ item?.title }}</h4>
      <img
        src="{{ item?.image }}"
        alt=""
        style="width: -webkit-fill-available; margin: 18px 0; height: 200px"
      />
      <p>
        {{ item?.introduction | slice : 0 : 150
        }}{{ item?.introduction.length > 150 ? "..." : "" }}
      </p>
    </div>
  </div>
  <div *ngIf="id" class="blog">
    <h2>{{ selectedBlogs?.title }}</h2>
    <img
      src="{{ selectedBlogs?.image }}"
      alt=""
      style="width: -webkit-fill-available; margin: 18px 0"
    />

    <p style="margin-top: 24px">{{ selectedBlogs?.introduction }}</p>

    <ol>
      <li *ngFor="let item of selectedBlogs?.contents" style="font-weight: 500">
        {{ item?.title }}
        <p style="margin-top: 18px; font-weight: normal">{{ item?.content }}</p>
      </li>
    </ol>
    <h5 style="margin-top: 36px">Conclusion:</h5>
    <p>{{ selectedBlogs?.conclusion }}</p>
  </div>
</div>
<app-footer></app-footer>
