import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './website/home/home.component';
import { CoursesComponent } from './website/courses/courses.component';
import { AboutComponent } from './website/about-us/about-us.component';
import { ContactUsComponent } from './website/contact-us/contact-us.component';
import { GalleryComponent } from './website/gallery/gallery.component';
import { MaterialComponent } from './website/material/material.component';
import { ExamsComponent } from './website/exams/exams.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { BlogsComponent } from './website/blogs/blogs.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  { path: 'home', component: HomeComponent },
  { path: 'course/:courseId', component: CoursesComponent },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin-routing.module').then((m) => m.AdminRoutingModule),
  },
  { path: 'aboutUs', component: AboutComponent },
  { path: 'contactUs', component: ContactUsComponent },
  { path: 'gallery', component: GalleryComponent },
  { path: 'getUploadFiles', component: MaterialComponent },
  { path: 'blogs', component: BlogsComponent },
  { path: 'blogs/:id', component: BlogsComponent },
  { path: 'exams/:examId', component: ExamsComponent },
  { path: '**', pathMatch: 'full', component: PagenotfoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
