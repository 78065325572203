import { Component } from '@angular/core';
import { HttpClient ,HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { ServiceApiService } from 'src/app/shared/service-api.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent {
  currentPassword: string = '';
  newPassword: string = '';
  confirmPassword: string = '';


   constructor(private router:Router , private http : HttpClient, private serviceApiService: ServiceApiService  ) { }


   changepassword() { 
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
  
    if (!userId || !token) {
      console.error('User ID or token not found');
      return;
    }
  
    let bodyData = {
      "currentPassword": this.currentPassword,
      "newPassword": this.newPassword,
      "confirmPassword": this.confirmPassword
    };
  
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      })
    };
  
    this.serviceApiService.updatePassword(userId, bodyData, httpOptions).subscribe(
      (resultData: any) => {
        console.log(resultData);
        alert(resultData);
        this.currentPassword = '';
        this.newPassword = '';
        this.confirmPassword = '';
      },
      (error) => {
        console.error('Error updating password:', error);
      }
    );
  }
  
}
