<app-header> </app-header>

<div class="pdf-section">
  <h2>Available PDF Resources</h2>
  <div *ngFor="let pdf of pdfList" class="pdf-item">
    <span>{{ pdf.title }}</span>
    <button (click)="verify(pdf.id)">Download PDF</button>
  </div>
</div>


<p-dialog header="Download PDF" [(visible)]="displayDialog" [modal]="true" [responsive]="true" styleClass="custom-dialog">
  <div class="pdf-item">
    <p>PDF Title: {{ selectedPdf?.title }}</p>
  </div>
 
<p-messages [value] ="messages"></p-messages>
<form [formGroup] = "loginFormGroup"> 


  <div class="inputs">
    <label for="input1">Gr Num:</label>
    <input id="input1" formControlName="grNum" placeholder="Enter your GrNo." required />

    <label for="input2">Div:</label>
    <input id="input2" formControlName="div" placeholder="Enter your Division" required />

    <label for="input3">std:</label>
    <input id="input3" formControlName="std" placeholder="Enter your STD" required/>

    <button >Download</button>
  </div>
</form>
</p-dialog>



<app-footer></app-footer>