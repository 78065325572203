import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceApiService} from '../../shared/service-api.service';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css']
})
export class CoursesComponent implements OnInit {
  details: any[] = [];
  courseId: string | null;
  courses : any = [];
  activeIndex = 0;
  locations : any = [];

  

  constructor(private http: HttpClient, private route: ActivatedRoute, private router: Router ,private serviceApiService : ServiceApiService) {
    this.courseId = this.route.snapshot.paramMap.get('courseId');
  }

  ngOnInit(): void {
  
    this.getCourseDetails();

    
  }

  getCourseDetails() {
    if (this.courseId) { 
    
      this.serviceApiService.getCourseDetails(this.courseId).subscribe(
        (data: any) => {
          console.log(data);
          this.details = data;
        },
        (error) => {
          console.error('Error fetching course details:', error);
        }
      );
    }
  }






  



}
