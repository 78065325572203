import { Component, OnInit } from '@angular/core';
import { HttpClient ,HttpParams} from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ServiceApiService } from '../../shared/service-api.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-material',
  templateUrl: './material.component.html',
  styleUrls: ['./material.component.css']
})
export class MaterialComponent implements OnInit {

  currectfile: string = " ";
  selectedPdf: any = {};
  pdfList: any[] = [];
  messages: any[] = [];
  displayDialog = false;

  loginFormGroup: FormGroup;

  constructor(
    private http: HttpClient,
    private serviceApiService: ServiceApiService,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private formBuilder: FormBuilder
  ) {
    this.loginFormGroup = this.formBuilder.group({
      grNum: [0, Validators.required],
      div: ['', Validators.required],
      std: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    // this.getFile();
  }

  verify(pdfId: number): void {
    this.selectedPdf = this.pdfList.find(pdf => pdf.id === pdfId);
    this.displayDialog = true;
  }

  getFile(): void {
    const listId = this.route.snapshot.queryParamMap.get('list_id');
    if (listId) {
      // If 'list_id' query parameter exists, pass it to the service method
      this.serviceApiService.getFiles().subscribe(data => {
        this.pdfList = data;
        console.log(this.pdfList);
      });
    } else {
      // Handle the case where 'list_id' query parameter is not present
      console.error('list_id query parameter is missing');
    }
  }
  


  downloadPdf(filename: string): void {
    const grNum = this.loginFormGroup.get('grNum')!.value;
    const div = this.loginFormGroup.get('div')!.value;
    const std = this.loginFormGroup.get('std')!.value;
  
    if (!grNum || !div || !std) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invalid request. Please provide grNum, div, and std.' });
      return;
    }
  
    const body = {
      grNum: grNum,
      div: div,
      std: std
    };
  
    this.http.post(`http://localhost:8080/download/${filename}`, body, {
      responseType: 'arraybuffer' // Set the response type to arraybuffer
    }).subscribe(
      (response) => {
        const blob = new Blob([response], { type: 'application/octet-stream' });
        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = filename;
  
        document.body.appendChild(a);
        a.click();
  
        window.URL.revokeObjectURL(blobUrl);
        document.body.removeChild(a);
  
        // Close the dialog
        this.displayDialog = false;
      },
      (error) => {
        if (error.status === 500) {
          console.error('Error adding admin:', error);
          this.messageService.add({
            severity: 'error',
            summary: 'Server Error',
            detail: 'An error occurred while processing your request. Kindly contact support.'
          });
        } else if (error.status === 400) {
          this.messageService.add({
            severity: 'error',
            summary: 'User Not Found',
            detail: 'Invalid request. Please provide grNum, div, and std.'
          });
        }  else if (error.status === 403) {
          this.messageService.add({
            severity: 'error',
            summary: 'User Not Found',
            detail: 'Access denied. Student not found.'
          })
        } 
        else if (error.status === 404) {
          this.messageService.add({
            severity: 'error',
            summary: 'User Not Found',
            detail: 'File not found in study material.'
          })
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'An unexpected error occurred. Please try again later.'
          });
        }
      }
    );
  }
  
}
