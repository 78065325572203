// dashboard.component.ts
import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Router, RouterLink } from '@angular/router';
import { Token } from '@angular/compiler';


@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  sidebarVisible: boolean = false;
  loggedInUser: any;
  items: MenuItem[] | undefined;


  constructor(private router: Router) {}
  
  ngOnInit() {

    if(localStorage.getItem('token') == null) {
      this.router.navigate(['/admin/login']);
    }

    // if(path : "**" ){
    //   then dont shouw dashboard
    // }
    
    this.items = [
      { label: 'Change Password', icon: 'pi pi-fw pi-lock',routerLink : 'admin/changePassword' },
      { label: 'Logout', icon: 'pi pi-fw pi-sign-out', command: () => this.logout() }
    ];

    // Assuming you have a way to retrieve the user information, for example from a service.
    this.loggedInUser = { email: localStorage.getItem('email') };
  }

  toggleSidebar() {
    this.sidebarVisible = !this.sidebarVisible;
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    this.router.navigate(['/admin/login']);
    // Additional logout logic can be added here if needed
  }
}
