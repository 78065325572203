import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

import { ServiceApiService} from '../../shared/service-api.service';



@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutComponent {
  activeIndex = 0;
  aboutUs : any = [];
  locations : any = [];
  courses : any = [];
  whyUs : any = [];


  
    constructor(private serviceApiService : ServiceApiService) { }


 


    ngOnInit(): void {
    this.getaboutUs();
 
    this.getwhyUs();

  }


    
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    getaboutUs() {
      this.serviceApiService.getAboutUser().subscribe(
        (data) => {
          console.log(data);
          
          this.aboutUs = data;
        },
        (error) => {
          console.error('Error fetching courses:', error);
        }
      );
    }


  ///////////////////////////////////////////////////////////////////////////////////////////////////////


  getwhyUs() {
    this.serviceApiService.getWhyUs().subscribe(
      (data) => {
        console.log(data);
        
        this.whyUs = data;
      },
      (error) => {
        console.error('Error fetching courses:', error);
      }
    );

  }
}
