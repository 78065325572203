import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceApiService } from '../../shared/service-api.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent {
  courses: any = [];
  activeIndex = 0;
  aboutUs: any = [];
  whyUs: any = [];
  testimonals: any = [];
  locations: any = [];
  exams: any = [];
  showHello: any = '';
  courseId: Number = 0;
  foudationalCourse: any = [];
  isActive = true;
  banner: any = [];
  isHovered: boolean = false;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private serviceApiService: ServiceApiService
  ) {}

  ngOnInit(): void {
    this.getCourses();

    this.getLocation();
  }

  getLocation() {
    this.serviceApiService.getLocation().subscribe(
      (data) => {
        console.log(data);

        this.locations = data;
      },
      (error) => {
        console.error('Error fetching courses:', error);
      }
    );
  }

  getCourses() {
    this.serviceApiService.coursesExams().subscribe(
      (data) => {
        console.log(data);

        this.courses = data;
      },
      (error) => {
        console.error('Error fetching courses:', error);
      }
    );
  }
  onClickCourse(item: any) {
    if (item.type == 'competetive') {
      this.router.navigate(['/exams/' + item.id]).then(() => {
        window.location.reload();
      });
    } else {
      this.router.navigate(['/course/' + item.id]).then(() => {
        window.location.reload();
      });
    }
  }
}
