<!-- testimonial-list.component.html -->
<div class="testimonials">
    <h1> Testimonial Section </h1>

    <form [formGroup]="form" class="form">

        <input type="text" formControlName="name" id="name" placeholder="Enter Testimonials name">
        <input type="text" formControlName="text" id="text" placeholder="Enter Testimonials text">
        <input type="number" formControlName="rating" id="rating" placeholder="Enter Testimonials rating">
        <div>
          <p-fileUpload name="currencyImage"
                       url="apiUrl"
                       (onSelect)="onFileSelected($event)"
                       [multiple]="true"
                       accept="image/*">
          </p-fileUpload>
        </div>
      
        <button class="add-btn" (click)="addTestimonial()">Add Testimonials</button>
    
      </form>

    <div *ngFor="let testimonial of testimonials" class="testimonial-item">
      <span>{{ testimonial.name }}</span>
      <span>{{ testimonial.text }}</span>
      <span>{{ testimonial.rating }}</span>
    
      <div class="button-group">
        <button class="edit-btn" (click)="edit(testimonial)">Edit</button>
        <button class="delete-btn" (click)="del(testimonial)">Delete</button>
    </div>
    </div>
  </div>
  


<!-- ////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
<p-dialog [(visible)]="updateBox" [modal]="true" [draggable]="true" [resizable]="true">
  <div class="dialog-content">
    <form [formGroup]="form">
      <div>
        <label for="name">Name :</label>
        <input type="text" formControlName="name" id="name" pInputText required>
      </div>

      <div>
        <label for="text">Text :</label>
        <input type="text" formControlName="text" id="text" pInputText required>
      </div>

      <div>
        <label for="rating">Rating :</label>
        <input type="number" formControlName="rating" id="rating" pInputText required>
      </div>

      <div class="input-group">
        <label class="upload-label">File Upload: </label>
        <p-fileUpload name="photo" (onSelect)="onFileSelected($event)" accept="image/*" 
                      [maxFileSize]="maxFileSize" mode="basic" [showUploadButton]="showUploadButton"
                      [url]="apiUrl">
        </p-fileUpload>
      </div>

      <div class="error-message" *ngIf="ErrorMessage">
        {{ ErrorMessage }}
      </div>
    </form>
  </div>

  <ng-template pTemplate="footer">
    <p-footer>
      <p-button (click)="editTestimonial()" label="Submit" styleClass="p-button-raised p-button-success"></p-button>
      <button pButton type="button" (click)="closeDialog()" label="Cancel"></button>
    </p-footer>
  </ng-template>
</p-dialog>


<!-- ////////////////////////////////////////////////////////////////////////////////////////////////////////// -->

<!-- /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->


<p-dialog [(visible)]="delBox" [modal]="true" [draggable]="true" [resizable]="true" class="del-dialog">
  <div class="del-header">
    <h1>Are You Sure You Want To Delete?</h1>
  </div>
  
  <ng-template pTemplate="footer">
    <p-footer>
      <p-button type="button" (click)="deleteTestimonial()" styleClass="p-button-raised p-button-danger">Delete</p-button>
      <button pButton type="button" (click)="closeDialog()" label="Cancel" ></button>
    </p-footer>
  </ng-template>
  </p-dialog>
  
