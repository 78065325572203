import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceApiService } from '../../shared/service-api.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css'],
})
export class GalleryComponent {
  activeIndex = 0;
  aboutUs: any = [];
  locations: any = [];
  courses: any = [];
  whyUs: any = [];
  galleryImages: any = [];

  responsiveOptions: any[] = [
    {
        breakpoint: '1024px',
        numVisible: 5
    },
    {
        breakpoint: '768px',
        numVisible: 3
    },
    {
        breakpoint: '560px',
        numVisible: 1
    }
];

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private serviceApiService: ServiceApiService
  ) {}

  ngOnInit(): void {
    this.getImages();

   
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  getImages() {
    this.serviceApiService.getImages().subscribe(
      (data) => {
        console.log(data);

        this.galleryImages = data;
        console.log(this.galleryImages);
      },
      (error) => {
        console.error('Error fetching courses:', error);
      }
    );
  }
}
