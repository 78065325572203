import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceApiService} from '../../shared/service-api.service';


@Component({
  selector: 'app-exams',
  templateUrl: './exams.component.html',
  styleUrls: ['./exams.component.css']
})
export class ExamsComponent implements OnInit {
  details: any = [];
  examId: string | null;
  courses : any = [];
  activeIndex = 0;
  locations : any = [];

  

  constructor(private http: HttpClient, private route: ActivatedRoute, private router: Router ,private serviceApiService : ServiceApiService) {
   
    
    this.examId = this.route.snapshot.paramMap.get('examId');
  }

  ngOnInit(): void {
  
    this.getCourseDetails();

    
  }

  getCourseDetails() {
    if (this.examId) { 
      this.serviceApiService.getExamDetails(this.examId).subscribe(
        (data: any) => {
          console.log(data);
          this.details = data;
          console.log(this.details);
          
        },
        (error) => {
          console.error('Error fetching course details:', error);
        }
      );
    }
  }
}
