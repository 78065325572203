<div>
  <footer class="footer">
    <img
      src="../../../assets/images/avalon White Logo.png"
      alt="logo"
      class="footerImage"
    />

    <div
      class="footer-content"
      data-aos="zoom-in-up"
      data-aos-duration="1000"
      data-aos-easing="linear"
    >
      <div class="locations">
        <ul class="location-list">
          <h3>Contact Us</h3>
          <!-- <br />
          <br /> -->
          <!-- <p *ngFor="let location of locations">
            {{ location.name }} {{ location.location_type }} <br />
            {{ location.address }} {{ location.city }} {{ location.pincode }}
          </p> -->
          <p style="color: grey; font-weight: 500">
            <i class="pi pi-map-marker"></i>&nbsp;AVALON ADDRESS:
          </p>
          <p>
            New Bombay Safalya CHS, Shop No- 1,2,4,9,15,16,17, Plot No. 14,
            Sector No: 7, Airoli, Navi Mumbai- 400708.
          </p>
          <p style="color: grey; font-weight: 500">
            <i class="pi pi-map-marker"></i>&nbsp;AIMS ADDRESS:
          </p>
          <p>
            Dattaray Maharaj CHS Ltd, B-Wing, Shop No:- 9,10,11,12 and 16 , Plot
            No-6, Sector No. 8, Airoli 400708.
          </p>
          <p>
            <a href="tel:+919867923189" style="text-decoration: none"
              ><i class="pi pi-phone"></i>&nbsp;+91-9867923189</a
            >
          </p>
        </ul>
      </div>

      <div class="footer-links">
        <h3>SiteMap</h3>
        <div class="sub-footer-links">
          <ul>
            <li></li>
            <li>
              <i
                class="pi pi-home"
                style="font-size: 1rem; color: #000; margin-right: 20px"
              ></i>
              <a href="">Home</a>
            </li>

            <li>
              <i
                class="pi pi-users"
                style="font-size: 1rem; color: #000; margin-right: 20px"
              ></i>
              <a href="aboutUs">About Us</a>
            </li>
            <li>
              <i
                class="pi pi-images"
                style="font-size: 1rem; color: #000; margin-right: 20px"
              ></i>
              <a href="gallery">Gallery</a>
            </li>
            <li>
              <i
                class="pi pi-phone"
                style="font-size: 1rem; color: #000; margin-right: 20px"
              ></i>
              <a href="contactUs">Contact Us</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="footerCourse">
        <h3>Courses</h3>
        <div class="subfooterCourses">
          <div
            *ngFor="let course of courses"
            class="courseItem"
            (click)="onClickCourse(course)"
          >
            <span>{{ course.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="footer-content1"
      data-aos="zoom-in-up"
      data-aos-duration="1000"
      data-aos-easing="linear"
    >
      <div class="courses-links" >
        <h2>JEE MAIN</h2>
        <a href="#">Avalon JEE Main Coaching</a>
        <a href="#">Avalon JEE main</a>
        <a href="#">Best JEE advance Coaching</a>
        <a href="#">Top JEE coaching Near me</a>
      </div>
      <div class="courses-links">
        <h2>JEE Advance</h2>
        <a href="#">Avalon JEE Advance coaching</a>
        <a href="#">Avalon JEE Advance classes</a>
        <a href="#">Best JEE Coaching</a>
        <a href="#">Top JEE advance coaching for 2025</a>
        <a href="#">Top JEE coaching Near me</a>
      </div>
      <div class="courses-links">
        <h2>NEET</h2>
        <a href="#">Avalon NEET coaching</a>
        <a href="#">Avalon NEET main</a>
        <a href="#">Top NEET coaching near me</a>
      </div>
    </div>
    <div class="footer2">
      <div class="logos">
        <a href="https://www.facebook.com/learn.avalon/" target="_blank"
          ><i
            class="pi pi-facebook"
            style="
              font-size: 1.5rem;
              background-color: #fff;
              color: #25469a;
              border-radius: 16px;
              padding: 6px;
            "
          ></i
        ></a>
        <a href="https://www.instagram.com/learnavalon/" target="_blank"
          ><i
            class="pi pi-instagram"
            style="
              font-size: 1.5rem;
              background-color: #fff;
              color: #25469a;
              border-radius: 16px;
              padding: 6px;
            "
          ></i
        ></a>
        <a href="#" target="_blank"
          ><i
            class="pi pi-linkedin"
            style="
              font-size: 1.5rem;
              background-color: #fff;
              color: #25469a;
              border-radius: 16px;
              padding: 6px;
            "
          ></i
        ></a>
      </div>
    </div>

    <div class="footer-bottom">
      <img src="../../../assets/images/Asset 7@4x.png" alt="Logo" />
    </div>
  </footer>
</div>
