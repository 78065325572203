import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute  ,Router} from '@angular/router';
import { ServiceApiService } from 'src/app/shared/service-api.service';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.css']
})
export class LocationsComponent {
  newCourseName: string = '';
  courses : any = [];
  updateBox = false;
  delBox = false;
  selectedTestimonials = {};
  currentId : string = "";
  form: FormGroup;
  ErrorMessage: string = "";


  constructor( private http: HttpClient , private route: ActivatedRoute , private router: Router,private fb: FormBuilder , private serviceApiService: ServiceApiService) {


    this.form = this.fb.group({
      name: ['', Validators.required],
      location_type: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      pincode: ['', Validators.required]
    });
  
  }


  ngOnInit(): void {
    this.getCourse();
  }

  getCourse() {
   this.serviceApiService.getLocation().subscribe(
      (data) => {
        console.log(data);
        
        this.courses = data;
      },
      (error) => {
        console.error('Error fetching courses:', error);
      }
    );
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  addLocation() {
    if (this.form.valid) {
      const courseData = this.form.value;
     this.serviceApiService.addLocation( courseData).subscribe(
        response => {
          console.log('Course added successfully:', response);
          this.form.reset();
          this.getCourse();
        },
        error => {
          console.error('Error adding course:', error);
        }
      );
    } else {
      console.log('Invalid form data. Please check your inputs.');
    }
  }
  

  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  
  edit(course : any ): void {
    this.updateBox = true;
    this.selectedTestimonials = course; 
    this.form.patchValue(this.selectedTestimonials); 
   this.editCourseConfirmation(course);
  }
  
  
  
  editCourseConfirmation(course: any) {
    console.log(course);
    
    this.currentId = course.id; 
    console.log(this.currentId);
    
  }

  
  editCourse(): void {
    const formData = {
      name: this.form.get('name')!.value,
      location_type: this.form.get('location_type')!.value, // Corrected from 'description' to 'location_type'
      address: this.form.get('address')!.value, // Corrected from 'start_date' to 'address'
      city: this.form.get('city')!.value, // Corrected from 'end_date' to 'city'
      pincode: this.form.get('pincode')!.value, // Corrected from 'capacity' to 'pincode'
    };
  
    this.serviceApiService.editLocation(this.currentId, formData).subscribe(
      (resultData: any) => {
        console.log('Server Response:', resultData);
        // Assuming resultData is the updated course data received from the API
        this.form.patchValue(resultData); // Patch the form with updated data if needed
        this.updateBox = false;
        this.getCourse()
      },
      (error) => {
        console.error('Error updating course:', error);
      }
    );
  }
  


  //////////////////////////////////////////////////////////////////////////////////////////////////////////


  closeDialog() {
    this.updateBox = false;
    this.delBox = false;
  }
  
  del(course : any) : void {
    this.selectedTestimonials = { ...course };
    this.delBox = true;
    this.deleteCourseConfirmation(course);

  }

deleteCourseConfirmation(course: any) {
  console.log(course);
  
  this.currentId = course.id; 
  console.log(this.currentId);
  
}


deleteTestimonial(): void {
this.serviceApiService.deleteLocation(this.currentId).subscribe(
  (resultData: any) => {
    console.log(resultData);
    this.delBox = false;
    this.getCourse() ;
  },
  (error) => {
    console.error('Error deleting testimonial:', error);
    
  }
);
}
}
