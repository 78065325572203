<!-- dashboard.component.html -->
<div class="navbar">
    <div class="container">
      <div class="logo-container">
        <p-button class="toggleButton" (click)="toggleSidebar()" icon="pi pi-align-justify"></p-button>
  
        <p *ngIf="loggedInUser" class="email">{{ loggedInUser.email }}</p>
  
        <p-button (click)="profileMenu.toggle($event)" class="userInfo">
          <i class="pi pi-user" style="font-size: 1rem;"></i>
        </p-button>
  
        <p-menu #profileMenu [model]="items" [popup]="true"></p-menu>
      </div>
    </div>
  </div>
  


<p-sidebar [(visible)]="sidebarVisible">
    <ng-template pTemplate="header">
        <div class="sidebar-header">
            <img src="../../../assets/images/avalon logo.png" alt="Institution Logo" class="logo" />
          
            <h1>Welcome, Admin!</h1>
        </div>
    </ng-template>
    <ng-template pTemplate="content">
        <div class="sidebar-content">
            <!-- Sidebar content goes here -->
        
                    <p-divider></p-divider>
                    <a routerLink="/admin/about_us"  routerLinkActive="active">
                        <i class="pi pi-heart-fill"></i>&nbsp;About_us  </a>
                <p-divider></p-divider>
                <a routerLink="/admin/gallery"  routerLinkActive="active">
                    <i class="pi pi-camera"></i>&nbsp;Gallery  </a>
            <p-divider></p-divider>
            

                <a routerLink="/admin/testimonials"  routerLinkActive="active">
                    <i class="pi pi-users"></i>&nbsp;
                    Testimonials</a>
                
                <!-- <p-divider></p-divider>
                <a routerLink="/admin/courses"  routerLinkActive="active">
                    <i class="pi pi-book"></i>&nbsp;Courses</a> -->


                    <p-divider></p-divider>
                <a routerLink="/admin/exams"  routerLinkActive="active">
                    <i class="pi pi-book"></i>&nbsp;Courses and Exams</a>
                
                <p-divider></p-divider>
                <a routerLink="/admin/settings"  routerLinkActive="active">
                    <i class="pi pi-cog"></i>&nbsp;Settings</a>
                
                <p-divider></p-divider>
                <a routerLink="/admin/locations"  routerLinkActive="active">
                    <i class="pi pi-compass"></i>&nbsp;locations  </a>
                
                
                
                <p-divider></p-divider>
                <a routerLink="/admin/why_us"  routerLinkActive="active">
                    <i class="pi pi-heart"></i>&nbsp;why_us  </a>
                
                <p-divider></p-divider>

                <a routerLink="/admin/uploads"  routerLinkActive="active">
                    <i class="pi pi-upload"></i>&nbsp;uploads  </a>
                
                <p-divider></p-divider>
               
        
        </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <div class="sidebar-footer">
                <p *ngIf="loggedInUser" class="email">{{ loggedInUser.email }}</p>
    
            </div>
        </ng-template>
</p-sidebar>


.

