import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceApiService } from 'src/app/shared/service-api.service';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.css'],
})
export class BlogsComponent implements OnInit {
  id: any;
  blogs: any = [
    {
      id: 1,
      image: '../../../assets/images/1.jpg',
      title:
        'Exploring the Wonders of the Universe with Avalon Learning Solutions',
      introduction:
        "Welcome to Avalon Learning Solutions, where we embark on a journey to explore the wonders of the universe! In this blog series, we'll delve into the mysteries of outer space, uncover the latest discoveries in astronomy, and marvel at the awe-inspiring phenomena that shape our cosmos. Join us as we journey through the vast expanse of space and unlock the secrets of the universe together.",
      conclusion:
        "Thank you for joining us on this cosmic journey through the wonders of the universe. Whether you're a seasoned stargazer or a curious novice, Avalon Learning Solutions is here to guide you on your exploration of the cosmos. Stay tuned for more captivating content, celestial discoveries, and astronomical adventures. Until next time, keep looking up!",
      contents: [
        {
          title: 'The Beauty of the Night Sky',
          content:
            'Discover the enchanting beauty of the night sky with Avalon Learning Solutions. Learn how to identify constellations, observe celestial events such as meteor showers and eclipses, and deepen your appreciation for the wonders of the cosmos.',
        },
        {
          title: 'Exploring the Solar System',
          content:
            'Take a virtual tour of our solar system and explore the fascinating worlds that orbit our sun. From the rocky terrain of Mars to the icy moons of Jupiter, uncover the diverse landscapes and intriguing features of our celestial neighbors.',
        },
        {
          title: 'The Marvels of Deep Space',
          content:
            'Journey beyond our solar system and into the depths of deep space, where galaxies, nebulae, and black holes await. Learn about the latest discoveries in astrophysics and cosmology, and ponder the profound questions that challenge our understanding of the universe.',
        },
        {
          title: 'Understanding the Universe',
          content:
            'Delve into the fundamental principles of astronomy and cosmology with Avalon Learning Solutions. Explore topics such as the Big Bang theory, dark matter, and the expanding universe, and gain insight into the scientific theories that shape our understanding of reality.',
        },
        {
          title: 'Inspiring the Next Generation',
          content:
            'At Avalon Learning Solutions, we believe in inspiring the next generation of astronomers, scientists, and explorers. Discover educational resources, hands-on activities, and interactive lessons designed to ignite curiosity and passion for the wonders of the universe.',
        },
      ],
    },
    {
      id: 2,
      image: '../../../assets/images/2.jpg',

      title:
        'Innovations in Science and Technology: Exploring the Frontiers of Human Ingenuity',
      introduction:
        "Welcome to Avalon Learning Solutions, where we embark on a thrilling exploration of the latest innovations in science and technology! In this blog series, we'll dive into the cutting-edge developments and groundbreaking discoveries that are shaping the future of our world. Join us as we journey through the frontiers of human ingenuity and uncover the incredible advancements that are revolutionizing industries, improving lives, and expanding the boundaries of knowledge.",
      conclusion:
        "Thank you for joining us on this exhilarating journey through the frontiers of innovation in science and technology. At Avalon Learning Solutions, we believe in the power of knowledge to inspire curiosity, drive progress, and shape a better future for all. Stay tuned for more captivating content, thought-provoking insights, and inspiring stories of human achievement. Together, let's continue to explore, innovate, and push the boundaries of what's possible in science and technology!        ",
      contents: [
        {
          title: 'Breakthroughs in Biotechnology',
          content:
            'Delve into the world of biotechnology and discover the groundbreaking innovations that are transforming healthcare, agriculture, and beyond. From gene editing technologies like CRISPR to advancements in personalized medicine and regenerative therapies, explore the remarkable ways in which biotechnology is revolutionizing our understanding of life and reshaping the future of medicine.',
        },
        {
          title: 'Revolutionizing Renewable Energy',
          content:
            'Explore the shift towards sustainable energy solutions and the remarkable innovations driving the renewable energy revolution. Learn about breakthroughs in solar, wind, and hydroelectric power, as well as emerging technologies like energy storage systems and smart grids. Discover how these advancements are accelerating the transition to a cleaner, more sustainable energy future.',
        },
        {
          title: 'The Rise of Artificial Intelligence',
          content:
            'Uncover the transformative power of artificial intelligence (AI) and its impact on virtually every aspect of society. From autonomous vehicles and virtual assistants to machine learning algorithms and predictive analytics, explore the diverse applications of AI and the opportunities—and challenges—it presents for the future of work, creativity, and ethics.',
        },
        {
          title: 'Exploring the Frontiers of Space Exploration',
          content:
            "Embark on a cosmic journey to the far reaches of the universe and beyond. Discover the latest innovations in space exploration, from cutting-edge spacecraft and telescopes to ambitious missions to Mars, the Moon, and beyond. Learn about the scientific discoveries and technological advancements that are expanding our understanding of the cosmos and paving the way for humanity's next great leap into space.",
        },
        {
          title: 'Advancements in Information Technology          ',
          content:
            'Delve into the rapidly evolving landscape of information technology (IT) and the innovations driving digital transformation across industries. From cloud computing and cybersecurity to blockchain technology and the Internet of Things (IoT), explore the disruptive technologies shaping the future of communication, collaboration, and connectivity in our increasingly interconnected world.',
        },
      ],
    },
    {
      id: 3,
      image: '../../../assets/images/3.jpg',

      title:
        "The Environmental Impact Series: Exploring Our Planet's Ecological Footprint",
      introduction:
        "Welcome to Avalon Learning Solutions' Environmental Impact Series, where we delve into the critical issues surrounding our planet's ecological health and sustainability. In this series, we'll shine a spotlight on the various ways human activity is impacting the environment and explore solutions to mitigate these effects. Join us as we deepen our understanding of the environmental challenges we face and work towards a more sustainable future for generations to come.",
      conclusion:
        "Thank you for joining us on this journey through Avalon Learning Solutions' Environmental Impact Series. By raising awareness of the environmental challenges we face and exploring actionable solutions, we can all play a role in safeguarding the health and sustainability of our planet. Stay tuned for more thought-provoking content, inspiring stories, and practical steps towards building a more resilient and sustainable future for all. Together, let's make a positive impact on the world around us! ",
      contents: [
        {
          title: 'The Carbon Footprint Dilemma          ',
          content:
            "In the inaugural post of our series, we'll tackle the complex issue of carbon emissions and their profound impact on climate change. From the sources of carbon emissions to their effects on global temperatures and ecosystems, we'll examine the urgency of reducing our carbon footprint and transitioning to renewable energy sources.",
        },
        {
          title: 'Plastic Pollution: A Global Crisis          ',
          content:
            'Explore the devastating effects of plastic pollution on our oceans, wildlife, and ecosystems. Learn about the staggering amount of plastic waste produced each year, its long-lasting environmental consequences, and innovative solutions to reduce, reuse, and recycle plastic materials.',
        },
        {
          title: 'Deforestation: The Vanishing Forests          ',
          content:
            "Delve into the alarming rate of deforestation and its catastrophic impact on biodiversity, carbon sequestration, and indigenous communities. Discover the causes of deforestation, including agriculture, logging, and urban expansion, and explore strategies for preserving and restoring our planet's precious forest ecosystems.",
        },
        {
          title: ' Water Scarcity: A Looming Crisis',
          content:
            "Examine the growing threat of water scarcity and its implications for human health, food security, and economic development. From overconsumption and pollution to climate change-induced droughts, we'll discuss the factors driving water scarcity and highlight the importance of conservation and sustainable water management practices.",
        },
        {
          title: ' Biodiversity Loss: Preserving Life on Earth          ',
          content:
            'Dive into the biodiversity crisis and the unprecedented rate of species extinction occurring worldwide. Learn about the vital role biodiversity plays in maintaining ecosystem stability, supporting human well-being, and providing essential ecosystem services. Explore efforts to protect and restore biodiversity through habitat conservation, species reintroduction, and sustainable land management practices.',
        },
      ],
    },
    {
      id: 4,
      image: '../../../assets/images/4.jpg',

      title:
        'Famous Scientists Spotlight: Illuminating the Minds Behind Milestones',
      introduction:
        "Welcome to Avalon Learning Solutions' Famous Scientists Spotlight, where we celebrate the remarkable contributions of visionaries who have shaped our understanding of the world. In this series, we'll shine a light on the lives and legacies of some of history's most influential scientists, whose discoveries and innovations have transformed society and inspired generations. Join us as we journey through the annals of scientific history and explore the fascinating stories behind these extraordinary individuals.",
      conclusion:
        "Thank you for joining us on this journey through Avalon Learning Solutions' Famous Scientists Spotlight. By shining a light on the lives and achievements of these extraordinary individuals, we honor their legacy and inspire future generations to pursue their own passions for discovery and innovation. Stay tuned for more captivating stories, illuminating insights, and inspiring journeys through the annals of scientific history. Together, let's celebrate the enduring spirit of curiosity, creativity, and ingenuity that defines the pursuit of knowledge.",
      contents: [
        {
          title: ' Marie Curie: Pioneer of Radioactivity          ',
          content:
            "In our inaugural spotlight, we pay tribute to Marie Curie, the trailblazing physicist and chemist whose pioneering research on radioactivity earned her two Nobel Prizes. Explore Curie's groundbreaking discoveries, her tireless dedication to scientific inquiry, and her enduring legacy as a role model for women in STEM fields.",
        },
        {
          title: 'Albert Einstein: Architect of Modern Physics',
          content:
            "Delve into the revolutionary theories of Albert Einstein, the iconic physicist whose insights transformed our understanding of space, time, and the universe. From his theory of relativity to his contributions to quantum mechanics, we'll uncover the genius behind Einstein's iconic equations and explore the profound impact of his work on modern science.            ",
        },
        {
          title: 'Ada Lovelace: The First Computer Programmer',
          content:
            "Celebrate the visionary intellect of Ada Lovelace, often regarded as the world's first computer programmer. Discover Lovelace's pioneering work with Charles Babbage's Analytical Engine, her visionary insights into the potential of computing technology, and her enduring legacy as a trailblazer for women in computing and technology.",
        },
        {
          title: 'Charles Darwin: Architect of Evolutionary Theory          ',
          content:
            "Embark on a journey through the life and work of Charles Darwin, the naturalist whose theory of evolution revolutionized our understanding of the natural world. Explore Darwin's voyages aboard the HMS Beagle, his observations of biodiversity and adaptation, and the profound implications of his theory of natural selection on biology, medicine, and society.",
        },
        {
          title: 'Rosalind Franklin: Unraveling the Structure of DNA          ',
          content:
            "Uncover the groundbreaking contributions of Rosalind Franklin, the pioneering scientist whose work was instrumental in elucidating the structure of DNA. Learn about Franklin's pioneering X-ray diffraction studies, her critical role in the discovery of the double helix structure of DNA, and her enduring legacy as a trailblazer for women in science.",
        },
      ],
    },
  ];
  selectedBlogs: any;
  constructor(
    private serviceApiService: ServiceApiService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id) {
      let item: any = this.blogs.filter((e: any) => e.id == this.id);
      this.selectedBlogs = item[0];
    }
  }
  ngOnInit(): void {
    console.log(this.id);
  }

  onClickBlog(id: any) {
    this.router.navigate(['/blogs/' + id]).then(() => {
      window.location.reload();
    });
  }
}
