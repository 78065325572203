import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute  ,Router} from '@angular/router';
import { ServiceApiService } from 'src/app/shared/service-api.service';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.css']
})
export class TestimonialsComponent implements OnInit {
  
  
  form: FormGroup;
  testimonials : any = [];
  updateBox = false;
  delBox = false;
  name : string = " ";
  text : string = " ";
  rating : number = 0;
  ErrorMessage: string = "";
  currentId : string = "";
  selectedTestimonials = { }
  selectedFile: File | null = null;
  maxFileSize: number = 10485760; // 10 MB in bytes
  showUploadButton: boolean = false; // Set to true if you want the upload button to be initially visible

  apiUrl: string = `http://localhost:8080/editTestimonials/${this.currentId}`;

  
  constructor(private fb: FormBuilder , private http: HttpClient , private route: ActivatedRoute , private router: Router , private serviceApiService : ServiceApiService) {
    this.form = this.fb.group({
      name: ['', Validators.required],
      text: ['', Validators.required],
      rating: ['', Validators.required]
    });
   }

  ngOnInit(): void {
  this.testimonial();
  }


  testimonial(){
    this.serviceApiService.getTestimonials().subscribe(
      (data) => {
        console.log(data);
        
        this.testimonials = data;
      },
      (error) => {
        console.error('Error fetching courses:', error);
      }
    );
  }

///////////////////////////////////////////////////////////////////////////////

onFileSelected(event: any): void {
 
  this.selectedFile = event.files[0];

}
  ///////////////////////////////////////////////////////////////////////////////////////////////
  del(course : any) : void {
    this.selectedTestimonials = { ...course };
    this.delBox = true;
    this.deleteCourseConfirmation(course);

  }

deleteCourseConfirmation(course: any) {
  console.log(course);
  
  this.currentId = course.id; 
  console.log(this.currentId);
  
}


deleteTestimonial(): void {

this.serviceApiService.deleteTestimonial(this.currentId).subscribe(
  (resultData: any) => {
    console.log(resultData);
    this.delBox = false;
    this.testimonial() ;
  },
  (error) => {
    console.error('Error deleting testimonial:', error);
    
  }
);
}



 

//////////////////////////////////////////////////////////////////
  


edit(course: any): void {
  this.updateBox = true;
  this.selectedTestimonials = course;
  this.form.patchValue(this.selectedTestimonials);
  this.editCourseConfirmation(course);
}

editCourseConfirmation(course: any): void {
  console.log(course);
  this.currentId = course.id;
  console.log(this.currentId);
}

closeDialog() {
  this.updateBox = false;
  this.delBox = false;
}



editTestimonial() {

  if (this.form.valid && this.selectedFile) {
    const { name, text, rating } = this.form.value;
    const formData = new FormData();
    formData.append('name', name);
    formData.append('text', text);
    formData.append('rating', rating.toString());
    formData.append('photo', this.selectedFile);

    
 console.log(this.form.value);
 
  this.serviceApiService.editTestimonial(this.currentId , formData).subscribe(
    (resultData: any) => {
      console.log(resultData);
      this.updateBox = false;
      this.selectedTestimonials = resultData;
      this.form.patchValue(this.selectedTestimonials);
      this.testimonial();
     
    },
    (error) => {
      console.error('Error updating user:', error);
      // this.authService.showError('An error occurred while upadating vendor');
    }
  );
}
}








//////////////////////////////////////////////////////////////////////////////////////////////////////
addTestimonial() {
  if (this.form.valid && this.selectedFile) {
    const { name, text, rating } = this.form.value;
    const formData = new FormData();
    formData.append('name', name);
    formData.append('text', text);
    formData.append('rating', rating.toString());
    formData.append('photo', this.selectedFile);

    
 console.log(this.form.value);
 
  this.serviceApiService.addTestimonial(formData).subscribe(
    (resultData: any) => {
      console.log(resultData);
      this.updateBox = false;
      this.selectedTestimonials = resultData;
      this.form.patchValue(this.selectedTestimonials);
      this.form.reset();
      this.testimonial();
     
    },
    (error) => {
      console.error('Error updating user:', error);
    }
  );
}
}

}
