import { Component } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import AOS from 'aos';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  ngOnInit(): void {
    AOS.init()
  }
  
  title = 'frontend';
  showAdminHead: boolean = false;

  constructor(private router: Router) {
    // on route change to '/login', set the variable showHead to false
      router.events.forEach((event) => {
        if (event instanceof NavigationStart) {
          console.log("EEEEEEEEEE",event['url']);
          if (event['url'] == '/admin/login') {
            this.showAdminHead = false;
          } else if(event['url'].includes('/admin/')) {
            console.log("NU")
            this.showAdminHead = true;
          }else {
            this.showAdminHead = false;
          }
        }
      });
    }
}
