import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServiceApiService } from '../../shared/service-api.service';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';

interface Exam {
  id: string;
  name: string;
}

interface List {
  label: string;
  items?: MenuItem[];
  queryLink?: string;
  routerLinkActiveOptions?: { exact: boolean };
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  isMenuOpen = false;
  exams: Exam[] = [];
  courses: any[] = [];
  items: MenuItem[] = [];
  foudationalCourse: any = [];
  banner: any = [];
  active: MenuItem | undefined;
  coursesExams: any = [];
  lists: List[] = [];

  constructor(
    private http: HttpClient,
    private serviceApiService: ServiceApiService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getExams();
    this.getFoundationalCourse();
    this.getCourses();
    this.getBanner();
    this.getcoursesExams();
    this.getList();
  }

  getFoundationalCourse() {
    this.serviceApiService.getFoundationalCourse().subscribe(
      (data: any) => {
        console.log(data);
        this.foudationalCourse = data;
        console.log(this.foudationalCourse);
      },
      (error) => {
        console.error('Error fetching foundational courses:', error);
      }
    );
  }

  getList() {
    this.serviceApiService.getLists().subscribe(
      (data: List[]) => {
        console.log(data);
        this.lists = data;
        this.createMenuItems();
      },
      (error) => {
        console.error('Error fetching exams:', error);
      }
    );
  }

  getExams() {
    this.serviceApiService.getExams().subscribe(
      (data: Exam[]) => {
        console.log(data);
        this.exams = data;
        this.createMenuItems();
      },
      (error) => {
        console.error('Error fetching exams:', error);
      }
    );
  }

  getCourses() {
    this.serviceApiService.getCourses().subscribe(
      (data: any[]) => {
        console.log(data);
        this.courses = data;
        this.createMenuItems();
      },
      (error) => {
        console.error('Error fetching courses:', error);
      }
    );
  }

  getcoursesExams() {
    this.serviceApiService.coursesExams().subscribe(
      (data) => {
        this.coursesExams = data;
        console.log(this.coursesExams);
        this.createMenuItems();
      },
      (error) => {
        console.error('Error fetching courses:', error);
      }
    );
  }

  createMenuItems() {
    this.items = [
      {
        label: 'Home',
        routerLink: '/',

        routerLinkActiveOptions: { exact: true },
      },
      {
        label: 'About Us',
        routerLink: '/aboutUs',
        routerLinkActiveOptions: { exact: true },
      },
      {
        label: 'Courses',

        items: [
          ...this.courses.map((course: any) => ({
            label: course.name,
            // routerLink: ['/course/' + course.id],
            command: (click: any) => {
              this.router.navigate(['/course/' + course.id])
              .then(() => {
                window.location.reload();
              });
            }, 
            // queryParams: { courseId: course.id },
            routerLinkActiveOptions: { exact: true },
          })),
          {
            label: 'Foundational Course',

            items: this.foudationalCourse.map((foundationalExam: any) => ({
              label: foundationalExam.name,
              command: (click: any) => {
                this.router.navigate(['/course/' + foundationalExam.id])
                .then(() => {
                  window.location.reload();
                });
              }, 

              // routerLink: ['/course/' + foundationalExam.id],
              // queryParams: { foundationalExam : foundationalExam.id },
              routerLinkActiveOptions: { exact: true },
            })),
          },
        ],
      },
      {
        label: 'Competitive Exams',
        items: [
          ...this.exams.map((exam: any) => ({
            label: exam.name,
            command: (click: any) => {
              this.router.navigate(['/exams/' + exam.id])
              .then(() => {
                window.location.reload();
              });
            }, 
            // routerLink: ['/exams/' + exam.id],
            // queryParams: { examId: exam.id },
            routerLinkActiveOptions: { exact: true },
          })),
        ],
      },

      {
        label: 'Gallery',

        routerLink: '/gallery',
        routerLinkActiveOptions: { exact: true },
      },
      {
        label: 'Contact Us',

        routerLink: '/contactUs',
        routerLinkActiveOptions: { exact: true },
      },
      {
        label: 'Blogs',
        routerLink: '/blogs',
        routerLinkActiveOptions: { exact: true },
      },
      {
        label: 'Study Material',
        items: this.coursesExams.map((courseExam: any) => ({
          label: courseExam.name,
          items: this.lists
            .filter((list: any) => list.course_id === courseExam.id)
            .map((filteredItem: any) => {
              if (!filteredItem.id) {
                console.error(
                  "Missing 'id' property in filteredItem:",
                  filteredItem
                );
                // Handle the error appropriately, e.g., return an empty object or provide a default ID.
                return {};
              }

              return {
                label: filteredItem.name,
                queryParams: { list_id: filteredItem.id },
                routerLink: ['/getUploadFiles'],
                routerLinkActiveOptions: { exact: true },
              };
            }),
        })),
      },
    ];
  }
  onClick(name: any) {
    // this.router.navigate([`/home`]);
    this.router.navigate(['/home'], { fragment: name });
  }

  getBanner() {
    this.serviceApiService.getBanner().subscribe(
      (data) => {
        console.log(data);

        this.banner = data;
      },
      (error) => {
        console.error('Error fetching courses:', error);
      }
    );
  }
}
