<div class="upper">
  <div class="gradient-background">
    <marquee
      direction="left"
      style="
        display: flex;
        align-items: center;
        height: -webkit-fill-available;
        width: 100%;
        color: #fff;
        font-weight: 500;
      "
    >
      Admissions Open- Secure Your Seat! | Admissions Open - Register Now |
      Admissions Open- Secure Your Seat! | Admissions Open - Register Now |
      Admissions Open- Secure Your Seat! | Admissions Open - Register Now |
      Admissions Open- Secure Your Seat! | Admissions Open - Register Now
    </marquee>
  </div>
  <div class="header">
    <!-- header.component.html -->
    <p-menubar [model]="items" class="navbar">
      <ng-template pTemplate="start">
        <img
          class="desktop-view"
          src="../../assets/images/avalon logo.png"
          height="30"
          style="margin-right: 20px"
        />
      </ng-template>
      <ng-template pTemplate="end">
        <img
          class="mobile-view mobile-view-img"
          src="../../assets/images/avalon logo.png"
          height="25"
        />

        <p-button class="Register" label="Enroll Now" (click)="onClick('enrollNow')"></p-button>
      </ng-template>
    </p-menubar>
    <p-carousel
      [value]="banner"
      [numVisible]="1"
      [numScroll]="1"
      [showIndicators]="false"
      [autoplayInterval]="3000"
      [showNavigators]="false"
      [circular]="true"
      class="custom-carousel"
    >
      <ng-template let-image pTemplate="item">
        <img
          [src]="image.image_url"
          alt="Carousel Image"
          class="carousel-image"
        />
      </ng-template>
    </p-carousel>
  </div>
</div>
<!-- [src]="image.image_url" -->
