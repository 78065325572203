import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceApiService } from 'src/app/shared/service-api.service';

@Component({
  selector: 'app-why-us',
  templateUrl: './why-us.component.html',
  styleUrls: ['./why-us.component.css']
})
export class WhyUsComponent {
  updateBox: boolean = false;
  delBox: boolean = false;
  currentId: string = '';
  aboutUs: any[] = [];
  selectedCourse = {}; 
  whyUsForm: FormGroup;
  ErrorMessage: string = "";

constructor(private fb: FormBuilder ,private http: HttpClient , private route: ActivatedRoute , private router: Router, private serviceApiService : ServiceApiService) {
  this.whyUsForm = this.fb.group({
    title: ['', Validators.required],
    content: ['', Validators.required],
    features: ['', Validators.required],
    benefits: ['', Validators.required],
    status: [1] 
  });

}


  
  ngOnInit(): void {
    this.getSocialLinks();
    console.log(this.getSocialLinks);
    
  }


  //////////////////////////////////////////////////////////////////////////////////////////////////////////

  getSocialLinks(): void {
    this.serviceApiService.getWhyUs().subscribe(
      (data: any) => {
        console.log(data);
        this.aboutUs = data;
        console.log(data);
        
      },
      (error) => {
        console.error('Error fetching social links:', error);
      }
    );
  }


  //////////////////////////////////////////////////////////////////////////////////////////////////////
  addWhyUs(): void {
    const formData = this.whyUsForm.value;
    this.serviceApiService.addWhyUs(formData).subscribe(
      (resultData: any) => {
        console.log('Server Response:', resultData);
        // Optionally, you can handle success response here
        this.whyUsForm.reset();
        this.getSocialLinks();
      },
      (error) => {
        console.error('Error adding data to why_us table:', error);
        // Optionally, you can handle error response here
      }
    );
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  del(course : any) : void {
    this.selectedCourse = { ...course };
    this.delBox = true;
    this.deleteCourseConfirmation(course);

  }

deleteCourseConfirmation(course: any) {
  console.log(course);
  
  this.currentId = course.id; 
  console.log(this.currentId);
  
}


deleteCourse(): void {
this.serviceApiService.deleteWhyUs(this.currentId).subscribe(
  (resultData: any) => {
    console.log(resultData);
    this.delBox = false;
    this.getSocialLinks() ;
  },
  (error) => {
    console.error('Error deleting testimonial:', error);
    
  }
);
}

closeDialog() {
  this.updateBox = false;
  this.delBox = false;
}

///////////////////////////////////////////////////////////////////////////////////


edit(course: any): void {
  this.updateBox = true;
  this.selectedCourse = course;
  this.whyUsForm.patchValue({
    title: course.title,
    content: course.content,
    features: course.features,
    benefits: course.benefits
  });
  this.editCourseConfirmation(course);
}

editCourseConfirmation(course: any): void {
  console.log(course);
  this.currentId = course.id;
  console.log(this.currentId);
}

editCourse(): void {
  const formData = {
    title: this.whyUsForm.get('title')!.value,
    content: this.whyUsForm.get('content')!.value,
    features: this.whyUsForm.get('features')!.value,
    benefits: this.whyUsForm.get('benefits')!.value
  };

  console.log(formData); // Log the form data before sending the request
  console.log(this.currentId);
  
  this.serviceApiService.editWhyUs(this.currentId , formData).subscribe(
    (resultData: any) => {
      console.log('Server Response:', resultData);
      this.selectedCourse = resultData;
      this.whyUsForm.patchValue(this.selectedCourse); // Assuming the API response contains the updated data
      this.updateBox = false;
      this.getSocialLinks();
    },
    (error) => {
      console.error('Error updating Why Us information:', error);
    }
  );
}


}
