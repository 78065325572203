import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceApiService } from 'src/app/shared/service-api.service';
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent {
  updateBox: boolean = false;
  delBox: boolean = false;
  currentId: string = '';
  aboutUs: any[] = [];
  selectedCourse = { };
  aboutUsForm: FormGroup;
  ErrorMessage: string = "";

  constructor(private fb: FormBuilder, private http: HttpClient, private route: ActivatedRoute, private router: Router , private serviceApiService : ServiceApiService) {
    this.aboutUsForm = this.fb.group({
      title: ['', Validators.required],
      content: ['', Validators.required],
      founder_name: ['', Validators.required], 
      
      status: [1]
  })
}


  ngOnInit(): void {
    this.getSocialLinks();
    console.log(this.getSocialLinks);
    
  }


  getSocialLinks(): void {
    this.serviceApiService.getAboutUser().subscribe(
      (data: any) => {
        console.log(data);
        this.aboutUs = data;
        console.log(data);
        
      },
      (error) => {
        console.error('Error fetching social links:', error);
      }
    );
  }


    //////////////////////////////////////////////////////////////////////////////////////////////////////
    addAboutUs(): void {
      const formData = this.aboutUsForm.value;
     this.serviceApiService.addAbout(formData).subscribe(
        (resultData: any) => {
          console.log('Server Response:', resultData);
          // Optionally, you can handle success response here
          this.aboutUsForm.reset();
          this.getSocialLinks();
        },
        (error) => {
          console.error('Error adding data to about us table:', error);
          // Optionally, you can handle error response here
        }
      );
    }
    
  
    /////////////////////////////////////////////////////////////////////////////////////////////////////
    del(course : any) : void {
      this.selectedCourse = { ...course };
      this.delBox = true;
      this.deleteCourseConfirmation(course);
  
    }
  
  deleteCourseConfirmation(course: any) {
    console.log(course);
    
    this.currentId = course.id; 
    console.log(this.currentId);
    
  }
  
  
  deleteCourse(): void {

  
  this.serviceApiService.deleteAbout(this.currentId).subscribe(
    (resultData: any) => {
      console.log(resultData);
      this.delBox = false;
      this.getSocialLinks() ;
    },
    (error) => {
      console.error('Error deleting testimonial:', error);
      
    }
  );
  }
  
  closeDialog() {
    this.updateBox = false;
    this.delBox = false;
  }



  ///////////////////////////////////////////////////////////////////////////////////////////////////

  edit(course: any): void {
    this.updateBox = true;
    this.selectedCourse = course;
    this.aboutUsForm.patchValue({
      title: course.title,
      content: course.content,
      founder_name: course.founder_name,
    });
    
    this.editCourseConfirmation(course);
  }
  
  editCourseConfirmation(course: any): void {
    console.log(course);
    this.currentId = course.id;
    console.log(this.currentId);
  }
  
  editCourse(): void {
    const formData = {
      title: this.aboutUsForm.get('title')!.value,
      content: this.aboutUsForm.get('content')!.value,
      founder_name: this.aboutUsForm.get('founder_name')!.value,
    };
  
    console.log(formData); // Log the form data before sending the request
  
    this.serviceApiService.editAbout(this.currentId, formData).subscribe(
      (resultData: any) => {
        console.log('Server Response:', resultData);
        this.selectedCourse = resultData;
        this.aboutUsForm.patchValue(this.selectedCourse); // Assuming the API response contains the updated data
        this.updateBox = false;
        this.getSocialLinks()
      },
      (error) => {
        console.error('Error updating Why Us information:', error);
      }
    );
  }
  

}
