<app-header> </app-header>
<div class="outerGallery">
  <h1 claas="galleryHeading">Gallery</h1>


  <div
    class="gallery1"
    data-aos="fade-up"
    data-aos-duration="1000"
    data-aos-easing="linear"
  >
    <div class="gallery-card-section" *ngFor="let image of galleryImages">
      <div class="gallery-card">
        <p-image
          [src]="image.image_url"
          alt="Image"
          [preview]="true"
          class="gallery-img"
        ></p-image>
        <div class="gallery-card-content">
          <h3>{{ image.title }}</h3>
          <p>{{ image.description }}</p>
        </div>
      </div>
    </div>
    <!-- <div class="gallery">
    <div
      *ngFor="let image of galleryImages"
      class="gallery-item"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-easing="linear"
    >
      <img
        [src]="image.image_url"
        [alt]="image.title"
        class="testimonial-image"
      />
      <h3>{{ image.title }}</h3>
      <p>{{ image.description }}</p>
    </div>
  </div> -->
  </div>

  <app-footer></app-footer>
</div>
