import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute  ,Router} from '@angular/router';
import { ServiceApiService} from '../../shared/service-api.service';
import AOS from 'aos';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {



  

  @ViewChild('slider', { static: true }) slider!: ElementRef;
  responsiveOptions: any[] | undefined;
  formData: FormGroup;
  courses : any = [];
  activeIndex = 0;
  aboutUs : any = [];
  whyUs : any = [];
  testimonals : any = [];
  locations : any = [];
  exams : any = [];
  showHello : any = "";
  courseId: Number = 0;
  foudationalCourse : any = [];
  isActive = true;
  banner : any = [];
  isHovered: boolean = false;
  galleryImages : any = [];
  socialImages : any = [];
  value : Number = 0;
  coursesExams : any = [];

  constructor(private fb: FormBuilder , private http: HttpClient , private route: ActivatedRoute , private router: Router , private serviceApiService : ServiceApiService) {
    this.formData = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      courseId: [null, Validators.required], // assuming selectedCourse is an object
      message: ['']
    });

  }

  ngOnInit(): void {
    this.responsiveOptions = [
      {
          breakpoint: '1400px',
          numVisible: 3,
          numScroll: 3
      },
      {
          breakpoint: '1220px',
          numVisible: 2,
          numScroll: 2
      },
      {
          breakpoint: '767px',
          numVisible: 1,
          numScroll: 1
      },
     
  ];
    AOS.init()
    this.getCourses() 
    this.getaboutUs()
    this.testimonial()
    this.getLocation()
    this.getwhyUs()
    this.getExams();
    this.getFoundationalCourse();
    this.getBanner();
    this.getImages();
    this.getSocialIamges();
    this.getcoursesExams();
  }


///////////////////////////////////////////////////////////////////////////////////////////////////////

navigateToCourse(courseId: string) {
  // Navigate to the route with the dynamic parameter
  this.router.navigate(['course', courseId]);
}
    
    testimonial(){
      this.serviceApiService.getTestimonials().subscribe(
        (data: any[]) => { // Assuming the data is an array of testimonials
          
          this.testimonals = data;
    
    
        },
        (error) => {
          console.error('Error fetching testimonals:', error);
        }
      );
    }



  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  getaboutUs() {
    this.serviceApiService.getAboutUser().subscribe(
      (data) => {
   
        
        this.aboutUs = data;
      },
      (error) => {
        console.error('Error fetching courses:', error);
      }
    );
  }



  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

getExams() {
  this.serviceApiService.getExams().subscribe(
    (data) => {
    
      
      this.exams = data;
    },
    (error) => {
      console.error('Error fetching courses:', error);
    }
  );
}

  ///////////////////////////////////////////////////////////////////////////////////////////////////////

  
  
  getCourses() {
    this.serviceApiService.getCourses().subscribe(
      (data) => {
        
        
        this.courses = data;
        console.log(this.courses);
        
      },
      (error) => {
        console.error('Error fetching courses:', error);
      }
    );
  }
  


  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  submitForm(): void {
    if (this.formData.valid) {
      const courseId = this.formData.value.courseId.id; // Extract the id property
      const courseData = {
        ...this.formData.value, 
        courseId: courseId // Add the extracted id to the courseData object
      };
    

      this.serviceApiService.formSubmit(courseData).subscribe(
        (response: any) => {
          console.log('Course added successfully:', response);
          
          this.formData.reset();
        },
        (error) => {
          console.error('Error adding course:', error);
        }
      );
    } else {
      this.formData.markAllAsTouched();
    }
  }



  ////////////////////////////////////////////////////////////////////////////////////////////////////////////


  getLocation() {
    this.serviceApiService.getLocation().subscribe(
      (data) => {
        
        
        this.locations = data;
      },
      (error) => {
        console.error('Error fetching courses:', error);
      }
    );

    }

////////////////////////////////////////////////////////////////////////////////////////////////////////


getFoundationalCourse(){
  this.serviceApiService.getFoundationalCourse().subscribe(
    (data) => {
      
      
      this.foudationalCourse = data;
    },
    (error) => {
      console.error('Error fetching courses:', error);
    }
  );

}

/////////////////////////////////////////////////////////////////////////////////////////////////////

    getwhyUs() {
      this.serviceApiService.getWhyUs().subscribe(
        (data) => {
          
          
          this.whyUs = data;
        },
        (error) => {
          console.error('Error fetching courses:', error);
        }
      );
  
    }


    getBanner() {
      this.serviceApiService.getBanner().subscribe(
        (data) => {
          
          
          this.banner = data;
        },
        (error) => {
          console.error('Error fetching courses:', error);
        }
      );
    }


 



  getImages() {
    this.serviceApiService.getImages().subscribe(
      (data) => {
        
        
        this.galleryImages = data;
        console.log(this.galleryImages);
        
      },
      (error) => {
        console.error('Error fetching courses:', error);
      }
    );
  }

  getSocialIamges() {
    this.serviceApiService.getSoicalImages().subscribe(
      (data) => {
        
        
        this.socialImages = data;
        console.log(this.socialImages);
        
      },
      (error) => {
        console.error('Error fetching courses:', error);
      }
    );
  }

  getcoursesExams () {
    this.serviceApiService.coursesExams().subscribe(
      (data) => {
        
        
        this.coursesExams = data;
        console.log(this.coursesExams);
        
      },
      (error) => {
        console.error('Error fetching courses:', error);
      }
    );
  }

}


