<div class="courses">
    <h1> Location Section </h1>

    <div class="form" [formGroup]="form">
      <input formControlName="name" placeholder="Enter Location Name">
      <input formControlName="location_type" placeholder="Enter Location Type">
      <input formControlName="address" placeholder="Enter Location Address">
      <input formControlName="city" placeholder="Enter City">
      <input formControlName="pincode" placeholder="Enter Pincode">
    
      <button class="add-btn" (click)="addLocation()">Add Location</button>
    </div>
    
    

    <div *ngFor="let course of courses" class="course-item">
        <span>{{ course.name }}</span>
        <span>{{ course.location_type }}</span>
        <span>{{ course.address }}</span>
        <span>{{ course.city }}</span>
        <span>{{ course.pincode }}</span>
        <div class="button-group">
            <button class="edit-btn" (click)="edit(course)">Edit</button>
            <button class="delete-btn" (click)="del(course)">Delete</button>
        </div>
    </div>
</div>


<!-- ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->



<p-dialog [(visible)]="delBox" [modal]="true" [draggable]="true" [resizable]="true" class="del-dialog">
    <div class="del-header">
      <h1>Are You Sure You Want To Delete?</h1>
    </div>
  
    <ng-template pTemplate="footer">
      <p-footer>
        <p-button type="button" (click)="deleteTestimonial()" styleClass="p-button-raised p-button-danger">Delete</p-button>
        <button pButton type="button" (click)="closeDialog()" label="Cancel" ></button>
      </p-footer>
    </ng-template>
  </p-dialog>


  <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->


  <p-dialog [(visible)]="updateBox" [modal]="true" [draggable]="true" [resizable]="true">
    <div class="dialog-content">
      <form [formGroup]="form">
        <div>
          <label for="name">Name :</label>
          <input type="text" formControlName="name" id="name" name="name" pInputText required>
        </div>
  
        <div>
          <label for="location_type">Location Type :</label>
          <input type="text" formControlName="location_type" id="location_type" name="location_type" pInputText required>
        </div>
  
        <div>
          <label for="address">Address :</label>
          <input type="text" formControlName="address" id="address" name="address" pInputText required>
        </div>
  
        <div>
          <label for="city">City :</label>
          <input type="text" formControlName="city" id="city" name="city" pInputText required>
        </div>
  
        <div>
          <label for="pincode">Pincode :</label>
          <input type="text" formControlName="pincode" id="pincode" name="pincode" pInputText required>
        </div>
      </form>
  
      <div class="error-message" *ngIf="ErrorMessage">
        {{ ErrorMessage }}
      </div>
    </div>
  
    <ng-template pTemplate="footer">
      <p-footer>
        <p-button (click)="editCourse()" label="Submit" styleClass="p-button-raised p-button-success"></p-button>
        <button pButton type="button" (click)="closeDialog()" label="Cancel"></button>
      </p-footer>
    </ng-template>
  </p-dialog>
  
    