<app-header> </app-header>

<div class="About-us">
  <div class="row">
    <h1 class="aboutUsHeading d-flex d-md-none">About Us</h1>

    <div class="col-md-7">
      <div class="aboutUsPhotoSection1">
        <img src="../../../assets/images/9915.jpg" alt="Course Image" />
        <h1>PROF. SANDEEP Sir</h1>
      </div>
      <div class="aboutUsPhotoSection2">
        <img src="../../../assets/images/9910.jpg" alt="Carousel Image" />
        <h1>PROF. SHILPA MA'AM</h1>
      </div>
    </div>
    <div class="col-md-5">
      <ng-container *ngFor="let Aboutus of aboutUs">
        <h1 class="aboutUsHeading d-none d-md-flex">{{ Aboutus.title }}</h1>
      </ng-container>
      <ng-container *ngFor="let Aboutus of aboutUs" >
        
        <h2 >{{ Aboutus.founder_name }}</h2>
      </ng-container>
      <ng-container *ngFor="let Aboutus of aboutUs">
        <p class="aboutUs-para">{{ Aboutus.content }}</p>
      </ng-container>
    </div>
  </div>
  <!-- <div class="aboutUsImage" data-aos="fade-right" data-aos-duration="4000">
    <div class="imgContainer">
      <img
        src="../../../assets/images/images.jpg"
        alt="Course Image"
        class="aboutUsPhoto1"
      />
      <h1 class="imageHeading1">PROF. SANDEEP SIr</h1>
      <img
        src="../../../assets/images/images.jpg"
        alt="Carousel Image"
        class="aboutUsPhoto2"
      />
      <h1 class="imageHeading2">PROF. SHILPA MA'AM</h1>
    </div>
  </div>

  <div
    class="aboutUsIntro"
    data-aos="fade-left"
    data-aos-duration="4000"
    data-aos-easing="linear"
  >
    <ng-container *ngFor="let Aboutus of aboutUs">
      <h1 class="aboutUsHeading">{{ Aboutus.title }}</h1>
    </ng-container>
    <ng-container *ngFor="let Aboutus of aboutUs">
      <h2>{{ Aboutus.founder_name }}</h2>
    </ng-container>
    <ng-container *ngFor="let Aboutus of aboutUs">
      <p class="aboutUs-para">{{ Aboutus.content }}</p>
    </ng-container>
  </div> -->
</div>

<div class="whyus">
  <ng-container
    *ngFor="let whyUs of whyUs"
    data-aos="fade-up"
    data-aos-duration="3000"
  >
    <h2 class="whyus-heading1">{{ whyUs.features }}</h2>
  </ng-container>
  <img
    src="../../../assets/images/Asset 17@4x.png"
    alt="img"
    data-aos="fade-up"
    data-aos-duration="1000"
    data-aos-easing="linear"
  />
  <ng-container *ngFor="let whyUs of whyUs">
    <p
      class="whyus-para"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-easing="linear"
    >
      {{ whyUs.content }}
    </p>
  </ng-container>
</div>

<app-footer></app-footer>
