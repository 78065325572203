<div class="settings">
    <h2>Social Links</h2>

    <div class="form" [formGroup]="socialLinksForm">
      <input formControlName="emails" placeholder="Enter email">
      <input formControlName="fb_llink" placeholder="Enter fb_llink">
      <input formControlName="description" placeholder="Enter description">
      <input formControlName="twitter_links" placeholder="Enter twitter_link">
      <input formControlName="insta_links" placeholder="Enter insta_link">
      
      <button class="add-btn" (click)="addLinks()">Add Testimonials</button>
  </div>
  
    <div *ngFor="let link of links" class="testimonial-item">
        <span>{{ link.emails }}</span>
        <span>{{link.description}}</span>
        <span>{{ link.fb_llink }}</span>
        <span>{{ link.twitter_links }}</span>
        <span>{{ link.insta_links }}</span>
        <div class="button-group">
            <button class="edit-btn" (click)="edit(link)">Edit</button>
            <button class="delete-btn" (click)="del(link)">Delete</button>
        </div>
    </div>
</div>



<p-dialog [(visible)]="delBox" [modal]="true" [draggable]="true" [resizable]="true" class="del-dialog">
    <div class="del-header">
      <h1>Are You Sure You Want To Delete?</h1>
    </div>
  
    <ng-template pTemplate="footer">
      <p-footer>
        <p-button type="button" (click)="deleteTestimonial()" styleClass="p-button-raised p-button-danger">Delete</p-button>
        <button pButton type="button" (click)="closeDialog()" label="Cancel" ></button>
      </p-footer>
    </ng-template>
  </p-dialog>



  <!-- /////////////////////////////////////////////////////////////////////////////////////////////////// -->


  <p-dialog [(visible)]="updateBox" [modal]="true" [draggable]="true" [resizable]="true">
   
      <form [formGroup]="socialLinksForm" class="dialog-content">
        <div>
          <label for="emails">Emails:</label>
          <input type="text" formControlName="emails" id="emails" pInputText required>
        </div>
    
        <div>
          <label for="fb_llink">Facebook Link:</label>
          <input type="text" formControlName="fb_llink" id="fb_llink" pInputText required>
        </div>
    
        <div>
          <label for="description">Description:</label>
          <input type="text" formControlName="description" id="description" pInputText required>
        </div>
    
        <div>
          <label for="twitter_links">Twitter Links:</label>
          <input type="text" formControlName="twitter_links" id="twitter_links" pInputText required>
        </div>
    
        <div>
          <label for="insta_links">Instagram Links:</label>
          <input type="text" formControlName="insta_links" id="insta_links" pInputText required>
        </div>
    
    
        <div class="error-message" *ngIf="ErrorMessage">
          {{ ErrorMessage }}
        </div>
      </form>

    
    <ng-template pTemplate="footer">
      <p-footer>
        <p-button (click)="editTestimonial()" label="Submit" styleClass="p-button-raised p-button-success"></p-button>
        <button pButton type="button" (click)="closeDialog()" label="Cancel"></button>
      </p-footer>
    </ng-template>
  </p-dialog>