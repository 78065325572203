<div class="admin-login">

  
      
        <div class="welcome-container">
          <h1>Welcome Back!</h1>
          
         </div>
         <p-messages [value]="messages"></p-messages>
         <form [formGroup]="loginFormGroup" (ngSubmit)="loginForm()" class="login-form">
          <div class="mat-form-field">
            <label for="email">Email:</label>
            <input type="email" id="email" formControlName="email" placeholder="Enter your email" required>
          </div>
          <div class="mat-form-field">
            <label for="password">Password:</label>
            <input type="password" id="password" formControlName="password" placeholder="Enter your password" required>
          </div>
          <button type="submit" class="login-button">Login</button>
        
        </form>
        
  </div>
  