
<div class="gallery">
    <h1> Gallery Section </h1>
  
    <div class="form" [formGroup]="form">
      <input formControlName="title" placeholder="Enter Title">

      <input formControlName="description" placeholder="Enter Course Description">
      
      <div class="file-upload-section">
        <p style="margin: 0 auto; font-weight: bold; font-size: 20px; text-align: center; padding: 20px;">Insert Image</p>
        <p-fileUpload
          name="image_url"
          url="apiUrl"
          (onSelect)="onFileSelected($event)"
          [multiple]="true"
          accept="image/*"
        ></p-fileUpload>
      </div>
      
      <label for="category" style="margin: 0 auto; font-weight: bold; font-size: 20px; text-align: center; margin-top: 20px;">Select a category :</label>
      <div class="p-field">
        <p-dropdown [options]="categories" formControlName="category" placeholder="Select a category" [showClear]="true"></p-dropdown>

      </div>
      
    
      <button class="add-btn" (click)="uploadGallery()">Add Gallery</button>
    </div>
    </div>
   
<div class="ShownImages"> 


    <div class="outerBanner">
     <h1> Banner Images</h1>
    <div class="BannerImages">
       
        <div *ngFor="let image of BannerImages" class="gallery-item" >
          <img [src]="image.image_url" [alt]="image.title" class="testimonial-image" />
          <h3>{{ image.title }}</h3>
          <p>{{ image.description }}</p>
          <button class="delete-btn"(click)="del(image)" >Delete</button>
        </div>
      </div>
    </div>


    <div class="outerBanner">
        <h1> Gallery Images</h1>
       <div class="BannerImages">
          
           <div *ngFor="let image of galleryImages" class="gallery-item" >
             <img [src]="image.image_url" [alt]="image.title" class="testimonial-image" />
             <h3>{{ image.title }}</h3>
             <p>{{ image.description }}</p>
             <button class="delete-btn" (click)="del(image)" >Delete</button>
           </div>
         </div>
       </div>

       
    <div class="outerBanner">
        <h1> Social Images</h1>
       <div class="BannerImages">
          
           <div *ngFor="let image of SocialImages" class="gallery-item" >
             <img [src]="image.image_url" [alt]="image.title" class="testimonial-image" />
             <h3>{{ image.title }}</h3>
             <p>{{ image.description }}</p>
             <button class="delete-btn" (click)="del(image)">Delete</button>
           </div>
         </div>
       </div>
    </div>
    


    <p-dialog [(visible)]="deleteBox" [modal]="true" [draggable]="true" [resizable]="true" class="del-dialog">
        <div class="del-header">
          <h1>Are You Sure You Want To Delete?</h1>
        </div>
      
        <ng-template pTemplate="footer">
          <p-footer>
            <p-button type="button" (click)="deleteGallery()" styleClass="p-button-raised p-button-danger">Delete</p-button>
            <button pButton type="button" (click)="closeDialog()" label="Cancel" ></button>
          </p-footer>
        </ng-template>
      </p-dialog>