import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'primeng/carousel'; // Import CarouselModule
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './website/home/home.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { TestimonialsComponent } from './admin/testimonials/testimonials.component';
import { CoursesComponentAdmin } from './admin/courses/courses.component';
import { SettingsComponent } from './admin/settings/settings.component';
import { MegaMenuModule } from 'primeng/megamenu';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms'; // Make sure FormsModule is also imported
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CoursesComponent } from './website/courses/courses.component';
import {SidebarModule} from 'primeng/sidebar';
import { DividerModule } from 'primeng/divider';
import { MenubarModule } from 'primeng/menubar';
import { MenuModule } from 'primeng/menu';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { LoginComponent } from './admin/login/login.component';
import { MessageService } from 'primeng/api';
import { LocationsComponent } from './admin/locations/locations.component';
import { AboutUsComponent } from './admin/about-us/about-us.component';
import { WhyUsComponent } from './admin/why-us/why-us.component';
import { CommonModule } from '@angular/common';
import { AboutComponent } from './website/about-us/about-us.component';
import { ContactUsComponent } from './website/contact-us/contact-us.component';
import { ToastModule } from 'primeng/toast';
import { GalleryComponent } from './website/gallery/gallery.component';
import { MaterialComponent } from './website/material/material.component';
import { MaterialUploadsComponent } from './admin/material-uploads/material-uploads.component';
import { ExamsComponent } from './website/exams/exams.component';
import { ExamsComponentAdmin } from './admin/exams/exams.component';
import { HeaderComponent } from './website/header/header.component';
import { FooterComponent } from './website/footer/footer.component';
import { RatingModule } from 'primeng/rating';
import { GalleryAdminComponent } from './admin/gallery-admin/gallery-admin.component';
import { ChangePasswordComponent } from './admin/change-password/change-password.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { GalleriaModule } from 'primeng/galleria';
import { ImageModule } from 'primeng/image';
import { BlogsComponent } from './website/blogs/blogs.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CoursesComponent,
    DashboardComponent,
    CoursesComponentAdmin,
    TestimonialsComponent,
    SettingsComponent,
    LoginComponent,
    LocationsComponent,
    AboutUsComponent,
    WhyUsComponent,
    AboutComponent,
    ContactUsComponent,
    GalleryComponent,
    MaterialComponent,
    MaterialUploadsComponent,
    ExamsComponent,
    ExamsComponentAdmin,
    HeaderComponent,
    FooterComponent,
    GalleryAdminComponent,
    ChangePasswordComponent,
    PagenotfoundComponent,
    BlogsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MegaMenuModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CarouselModule,
    SidebarModule,
    DividerModule,
    MenubarModule,
    MenuModule,
    DialogModule,
    FileUploadModule,
    CommonModule,
    ToastModule,
    RatingModule,ImageModule,GalleriaModule
  ],
  providers: [ MessageService],
  bootstrap: [AppComponent]
})
export class AppModule { }
