import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute  ,Router} from '@angular/router';
import { ServiceApiService } from 'src/app/shared/service-api.service';

@Component({
  selector: 'app-exams',
  templateUrl: './exams.component.html',
  styleUrls: ['./exams.component.css']
})
export class ExamsComponentAdmin implements OnInit {
 
  newCourseName: string = '';
  courses : any = [];
  delBox = false;
  currentId : string = "";
  updateBox = false; 
  selectedCourse = {}
  ErrorMessage: string = "";
  form: FormGroup;
  selectedFile: File | null = null;
  selectedFile1: File | null = null;
  maxFileSize: number = 10485760; // 10 MB in bytes
  showUploadButton: boolean = false;


  constructor( private http: HttpClient , private route: ActivatedRoute , private router: Router ,private fb: FormBuilder , private serviceApiService : ServiceApiService ) {
    
      this.form = this.fb.group({
        name: ['', Validators.required],
        description: ['', Validators.required],
        start_date: [''],
        end_date: [''],
        capacity: ['', Validators.required],
        current_enrollment: ['', Validators.required],
        type: ['',Validators.required]
      })
    

  }

  ngOnInit(): void {
    this.getCourse();
  }


  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
  getCourse() {
    this.serviceApiService.coursesExams().subscribe(
      (data) => {
        console.log(data);
        this.courses = data;
        },
      (error) => {
        console.error('Error fetching courses:', error);
      }
    );
  }

///////////////////////////////////////////////////////////////////////////////////////////////////////////////
onFileSelected(event: any): void {
  this.selectedFile = event.files[0];
}

onBackgroundImageSelected(event: any): void {
  this.selectedFile1 = event.files[0];
}


addCourse() {
  if (this.form.valid && this.selectedFile && this.selectedFile1) {
    const formData = new FormData();
    formData.append('name', this.form.value.name);
    formData.append('description', this.form.value.description);
    formData.append('start_date', this.form.value.start_date);
    formData.append('end_date', this.form.value.end_date);
    formData.append('capacity', this.form.value.capacity);
    formData.append('current_enrollment', this.form.value.current_enrollment);
    formData.append('type' , this.form.value.type);
    formData.append('photo', this.selectedFile);
    formData.append('background_photo', this.selectedFile1);

  this.serviceApiService.addExams( formData).subscribe(
      response => {
        console.log('Course added successfully:', response);
        this.form.reset();
        this.getCourse();
      },
      error => {
        console.error('Error adding course:', error);
      }
    );
  } else {
    console.log('Invalid form data. Please check your inputs.');
  }
}



  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  del(course : any) : void {
    this.selectedCourse = { ...course };
    this.delBox = true;
    this.deleteCourseConfirmation(course);

  }

deleteCourseConfirmation(course: any) {
  console.log(course);
  
  this.currentId = course.id; 
  console.log(this.currentId);
  
}


closeDialog() {
  this.updateBox = false
 this.updateBox = false; 
}

deleteCourse(): void {
this.serviceApiService.deleteExams(this.currentId).subscribe(
  (resultData: any) => {
    console.log(resultData);
    this.delBox = false;
    this.getCourse() ;
  },
  (error) => {
    console.error('Error deleting testimonial:', error);
    
  }
);
}



///////////////////////////////////////////////////////////////////////////////////////////////////////////


edit(course: any): void {
  this.updateBox = true;
  this.selectedCourse = course;
  this.form.patchValue(this.selectedCourse);
  this.editCourseConfirmation(course);
}

editCourseConfirmation(course: any): void {
  console.log(course);
  this.currentId = course.id;
  console.log(this.currentId);
}
editCourse(): void {
  const courseData = {
    name: this.form.get('name')!.value,
    description: this.form.get('description')!.value,
    start_date: this.form.get('start_date')!.value,
    end_date: this.form.get('end_date')!.value,
    capacity: this.form.get('capacity')!.value,
    current_enrollment: this.form.get('current_enrollment')!.value
  };

  console.log(courseData); // Log the form data before sending the request

  this.serviceApiService.editExams(this.currentId, courseData).subscribe(
    (resultData: any) => {
      console.log('Server Response:', resultData);
      this.selectedCourse = resultData;
      this.form.patchValue(this.selectedCourse);
      this.updateBox = false;

      this.getCourse();
    },
    (error) => {
      console.error('Error updating course:', error);
    }
  );
}

}

