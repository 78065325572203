<div class="courses">
    <h1> Competetive Exams Section </h1>
  
    <div class="form" [formGroup]="form">
      <input formControlName="name" placeholder="Enter Exam Name">

      <div class="file-upload-section">
        <p style="margin: 0 auto; font-weight: bold; font-size: 20px;">Book Image</p>
        <p-fileUpload
          name="photo"
          url="apiUrl"
          (onSelect)="onFileSelected($event)"
          [multiple]="true"
          accept="image/*"
        ></p-fileUpload>
      </div>


      <input formControlName="description" placeholder="Enter Exam Description">
      <input formControlName="capacity" placeholder="Enter Exam Capacity">
      <input formControlName="current_enrollment" placeholder="Enter Exam enrolled">
      <div class="file-upload-section">
        <p style="margin: 0 auto; font-weight: bold; font-size: 20px; ">Background Image for Course Section</p>
        <p-fileUpload
          name="background_photo"
          url="apiUrl"
          (onSelect)="onBackgroundImageSelected($event)"
          [multiple]="true"
          accept="image/*"
        ></p-fileUpload>
      </div>
    <input formControlName="type" id="type" placeholder="Enter Exam Type">
   
      <div class="date-search-container">
        <div class="date-range-container">
          <div class="date-input">
            <label for="startDate">Start Date:</label>
            <input type="date" id="startDate" class="date-picker" formControlName="start_date">
          </div>
          <div class="date-input">
            <label for="endDate">End Date:</label>
            <input type="date" id="endDate" class="date-picker" formControlName="end_date">
          </div>
        </div>
      </div>
      <button class="add-btn" (click)="addCourse()">Add Course</button>
    </div>
  
    
  
    <div *ngFor="let course of courses" class="course-item">
        <span>{{ course.name }}</span>
        <span>{{ course.start_date }}</span>
        <span>{{ course.end_date }}</span>
        <span>{{ course.type }}</span>
        <div class="button-group">
            <button class="edit-btn" (click)="edit(course)">Edit</button>
            <button class="delete-btn" (click)="del(course)"> Delete</button>
        </div>
    </div>
  </div>
  
  
  <!-- /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
  
  
  <p-dialog [(visible)]="delBox" [modal]="true" [draggable]="true" [resizable]="true" class="del-dialog">
  <div class="del-header">
    <h1>Are You Sure You Want To Delete?</h1>
  </div>
  
  <ng-template pTemplate="footer">
    <p-footer>
      <p-button type="button" (click)="deleteCourse()" styleClass="p-button-raised p-button-danger">Delete</p-button>
      <button pButton type="button" (click)="closeDialog()" label="Cancel" ></button>
    </p-footer>
  </ng-template>
  </p-dialog>
  
  
  
  <!-- ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
  
  <p-dialog [(visible)]="updateBox" [modal]="true" [draggable]="true" [resizable]="true">
  <div class="dialog-content">
    <form [formGroup]="form">
      <div>
        <label for="name">Name:</label>
        <input type="text" formControlName="name" id="name" pInputText required>
      </div>
    
      <div>
        <label for="description">Description:</label>
        <input type="text" formControlName="description" id="description" pInputText required>
      </div>
    
      <div class="date-search-container">
        <div class="date-range-container">
          <div class="date-input">
            <label for="startDate">Start Date:</label>
            <input type="date" formControlName="start_date" id="startDate"  class="date-picker">
          </div>
          <div class="date-input">
            <label for="endDate">End Date:</label>
            <input type="date" formControlName="end_date" id="endDate" name="endDate" class="date-picker">
          </div>
        </div>
      </div>
  
    
      <div>
        <label for="capacity">Capacity:</label>
        <input type="number" formControlName="capacity" id="capacity" pInputText required>
      </div>
    
      <div>
        <label for="current_enrollment">Current Enrollment:</label>
        <input type="number" formControlName="current_enrollment" id="current_enrollment" pInputText required>
      </div>

      <div>
        <label for="current_enrollment">Type:</label>
        <input type="number" formControlName="type" id="type" pInputText required>
      </div>
    
      <div class="error-message" *ngIf="ErrorMessage">
        {{ ErrorMessage }}
      </div>
    
      <ng-template pTemplate="footer">
        <p-footer>
          <p-button (click)="editCourse()" label="Submit" styleClass="p-button-raised p-button-success"></p-button>
          <button pButton type="button" (click)="closeDialog()" label="Cancel"></button>
        </p-footer>
      </ng-template>
    </form>
  </div>  
  
  <ng-template pTemplate="footer">
    <p-footer>
      <p-button (click)="editCourse()" label="Submit" styleClass="p-button-raised p-button-success"></p-button>
      <button pButton type="button" (click)="closeDialog()" label="Cancel"></button>
    </p-footer>
  </ng-template>
  </p-dialog>
  
  
  
  <!-- //////////////////////////////////////////////////////////////////////////////////////////////////////// -->

