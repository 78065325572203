import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Message } from 'primeng/api';
import { ServiceApiService } from 'src/app/shared/service-api.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  messages: Message[] = [];
  errorMessage: string = '';

  constructor(
    private router: Router,
    private http: HttpClient,
    private fb: FormBuilder,
    private messageService: MessageService,
    private serviceApiService: ServiceApiService
  ) {}

  loginFormGroup: FormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(6)]]
  });

  loginForm() {
    if (this.loginFormGroup.valid) {
      const { email, password } = this.loginFormGroup.value;


      this.serviceApiService.login(email, password).subscribe(
        (response: any) => {
          console.log(response.data.token)
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('email', response.data.email);
          localStorage.setItem('userId', response.data.userId
          
          
          );
          this.router.navigate(['/admin/courses']); 
        },
        (error: HttpErrorResponse) => {
         
          if (error.status === 404) {
            this.messageService.add({
              severity: 'error',
              summary: 'user not found',
              detail: 'user not found in database'
            });
          } else {
            this.errorMessage = 'Invalid email or password'; 
            this.messageService.add({ severity: 'error', summary: 'Error', detail: this.errorMessage });
          }
        }
      );
    } else {
      this.loginFormGroup.markAllAsTouched();
    }
  }

  ngOnInit(): void {
    if (!localStorage.getItem('token')) {
    this.router.navigate(['admin/login']);
    }
  }
}
